import React, { Component } from 'react';
import { API } from 'aws-amplify';
import {Link} from 'react-router-dom'
import StreamerCampBadge from './images/StreamerCampBadge3.png'
import moment from 'moment';
import Moment from 'react-moment';
import ScrollToBottom from 'react-scroll-to-bottom';


class Recaps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      liveblog : [],
    };
  }
  componentDidMount() {
    this.getLiveBlog();
    this.interval = setInterval(() => this.getLiveBlog(), 120000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.messagesEnd.parentNode.scrollTop = this.messagesEnd.offsetTop;
  }

  getLiveBlog = async () => {
    const response = await API.get('streamercampapi', '/blogs/Live Blog');
    this.setState({liveblog: response});
  }

  render() {    
    var liveblog = [];
    var date=null;
    
    for(let i = 0; i < this.state.liveblog.length; i++) {
      let orderDate = moment(this.state.liveblog[i].BlogTime).format('MMMM Do, YYYY');
      if (date !== orderDate) {
        liveblog.push(
          <div key={i + 'chat-day'} className="chat-day"><strong>{orderDate}</strong></div>
        )
        date = orderDate;
      }
      liveblog.push(
        <div key={i + 'chat-message'} className="chat-message">
        <div>
          <Moment local format="h:mm zz" date={this.state.liveblog[i].BlogTime}/>
          <img alt="Broadcaster" aria-label="Broadcaster badge" className="chat-badge" src="https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/4" srcSet="https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/1 1x, https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/2 2x, https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/3 4x"/>
          <img alt="Badge" aria-label="Verified badge" className="chat-badge" src={StreamerCampBadge} ></img>
          <img alt="Verified" aria-label="Verified badge" className="chat-badge" src="https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/4" srcSet="https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/1 1x, https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/2 2x, https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/3 4x"></img>
          <span className="chat-user">StreamerCampTV: </span><span dangerouslySetInnerHTML={{ __html : this.state.liveblog[i].BlogContent.replace("<p>", "").replace("</p>", "")}}></span>
        </div>
      </div>
      )
    }
    return (
        <div>
          <div className={"regular-font background-chat " + (this.props.homepage ? '' : 'recap-live-blog')}>
            <div className="chat-header">
              <div className="chat-header-left">Live Blog</div>
              {
                this.props.homepage &&
                <div className="chat-header-right">
                  <Link to='/recaps'><button>View All</button>
                  </Link>
                </div>
              }
            </div>
            <div className="chat-body">
                <div data-a-target="chat-welcome-message" className="chat-message chat-message-moderation"><span>Welcome to streamercamp.tv!</span></div>
                { liveblog }
                <div style={{ float:"left", clear: "both" }}
                    ref={(el) => { this.messagesEnd = el; }}>
                </div>
            </div>
          </div>
        </div>
                
    );
  }
}

export default Recaps;
