import React, { Component } from 'react';
import { API } from 'aws-amplify';


class VODs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clips: [],
      pictures: [],
      vods: [],
    };
  }
  componentDidMount() {
    this.getClips();
  }

  getClips = async () => {
    const response = await API.get('streamercampapi', '/media/Clip');
    this.setState({clips: response});
  }

  render() {

    var clips = this.state.clips.map((clip, key) =>
      <div key={key} className="col-md-3">
        <a href={clip.MediaLink} target="_blank" className="home-video-link" rel="noopener noreferrer">
          <div className="home-video-container">
            <img className="home-thumbnail sand-frame" src={clip.MediaThumbnail} alt=""/>
            <div className="home-thumbnail-description">
              <strong>{clip.MediaTitle}</strong>
            </div>
          </div>
        </a>
      </div>
    );

    return (
        <div id="content" className="content">
        <div className="section sand-background">
              <div className="row">
                  <div className="col-md-12">
                    <div className="home-subsection-banner">
                      Clips
                    </div>
                    <div className="row">
                      { clips }
                    </div>
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

export default VODs;
