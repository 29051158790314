import React, { Component } from 'react';
import { API } from 'aws-amplify';
import AdminMenu from './AdminMenu';
import AdminOrders from './AdminOrders';
import AdminBlogs from './AdminBlogs';
import AdminMedia from './AdminMedia';
import AdminLeaderboard from './AdminLeaderboard';
import { Navbar, Nav, NavItem, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Route, Router } from 'react-router-dom';
import history from '../history';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


var admins = [];

class Admin extends Component {
  state = {
    menuItems : [],
    orders: []
  }
  post = async () => {
    await API.post('streamercampapi', '/menuitems', {
      headers: {
        'Authorization': 'Bearer ' + this.props.auth.getIdToken()
      },
      body: {
        MenuItemId: '1',
        MenuItemTeam: 'Fuslie',
        MenuItemKeyWord: 'juice',
        MenuItemPrice: '100',
        MenuItemDescription: 'Apple Juice'
      }
    });
  }
  get = async () => {
    await API.get('streamercampapi', '/menuitems/object/1', {
      headers: {
        'Authorization': 'Bearer ' + this.props.auth.getIdToken()
      }});
  }
  list = async () => {
    await API.get('streamercampapi', '/menuitems/1', {
      headers: {
        'Authorization': 'Bearer ' + this.props.auth.getIdToken()
      }});
  }
  listAll = async () => {
    const response = await API.get('streamercampapi', '/menuitems', {
      headers: {
        'Authorization': 'Bearer ' + this.props.auth.getIdToken()
      }});
    this.setState({menuItems: response});
  }
  delete = async () => {
    await API.del('streamercampapi', '/menuitems/object/1', {
      headers: {
        'Authorization': 'Bearer ' + this.props.auth.getIdToken()
      }});
  }
  listAllOrders = async () => {
    const response = await API.get('streamercampapi', '/orders', {
      headers: {
        'Authorization': 'Bearer ' + this.props.auth.getIdToken()
      }});
    this.setState({orders: response});
  }
  goTo(route) {
    this.props.history.replace(`/${route}`)
  }
  login() {
    this.props.auth.login();
  }
  logout() {
    this.props.auth.logout();
  }
  componentDidMount(){
    this.listAll();
    this.listAllOrders();
    const { renewSession } = this.props.auth;

    if (localStorage.getItem('isLoggedIn') === 'true') {
      renewSession();
    }
  }
  render() {
    const { isAuthenticated } = this.props.auth;
    return (
      <div id="content" className="content admin">
        <Navbar fluid className="navbar-admin">
          {
            isAuthenticated() && (
              <Nav>
                <LinkContainer to="/admin/menu">
                  <NavItem className="admin-nav-item">
                    Menu
                  </NavItem>
                </LinkContainer>
                <LinkContainer to="/admin/orders">
                  <NavItem className="admin-nav-item">
                    Orders
                  </NavItem>
                </LinkContainer>
                <LinkContainer to="/admin/blogs">
                  <NavItem className="admin-nav-item">
                    Blogs
                  </NavItem>
                </LinkContainer>
                <LinkContainer to="/admin/media">
                  <NavItem className="admin-nav-item">
                    Media
                  </NavItem>
                </LinkContainer>
                <LinkContainer to="/admin/leaderboard">
                  <NavItem className="admin-nav-item">
                    Leaderboard
                  </NavItem>
                </LinkContainer>
                <LinkContainer to="/admin/admins">
                  <NavItem className="admin-nav-item">
                    Admins
                  </NavItem>
                </LinkContainer>
              </Nav>
            )
          }
          {
              !isAuthenticated() && (
                  <Button
                    id="qsLoginBtn"
                    bsStyle="primary"
                    className="btn-margin"
                    onClick={this.login.bind(this)}
                  >
                    Log In
                  </Button>
                )
            }
          {
            isAuthenticated() && (
          <Nav pullRight>
            <Button
              id="qsLogoutBtn"
              bsStyle="primary"
              className="btn-margin"
              onClick={this.logout.bind(this)}
            >
              Log Out
            </Button>
          </Nav>
            )
          }
      </Navbar>
               


        {
          isAuthenticated() && (
              <h4>
                You are logged in!
              </h4>
            )
        }
        {
          !isAuthenticated() && (
              <h4>
                You are not logged in! Please{' '}
                <a style={{ cursor: 'pointer' }}
                  onClick={this.login.bind(this)}>
                  Log In
                </a>
                {' '}to continue.
              </h4>
            )
        }
          <br/>
          <Router history={history}>
            <Route path="/admin/admins" render={(props) => <Admins initItems={admins} auth={this.props.auth}/>} />
            <Route path="/admin/menu" render={(props) => <AdminMenu auth={this.props.auth}/>} />
            <Route path="/admin/orders" render={(props) => <AdminOrders auth={this.props.auth}/>} />
            <Route path="/admin/blogs" render={(props) => <AdminBlogs auth={this.props.auth}/>} />
            <Route path="/admin/media" render={(props) => <AdminMedia auth={this.props.auth}/>} />
            <Route path="/admin/leaderboard" render={(props) => <AdminLeaderboard auth={this.props.auth}/>} />
          </Router>
      </div>
    );
  }
}

class AdminList extends React.Component {
  render () {
    var items = this.props.items.map((item, index) => {
      return (
        <AdminListItem key={index} item={item} index={index} removeItem={this.props.removeItem}/>
      );
    });
    return (
      <ul className="list-group"> {items} </ul>
    );
  }
}
  
class AdminListItem extends React.Component {
  constructor(props) {
    super(props);
    this.onClickClose = this.onClickClose.bind(this);
  }
  onClickClose() {
    var index = parseInt(this.props.index);
    this.props.removeItem(index);
  }
  render () {
    return(
      <li className="list-group-item ">
        <div>
          {this.props.item.value}
          <button type="button" className="close" onClick={this.onClickClose}>&times;</button>
        </div>
      </li>     
    );
  }
}

class AdminForm extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    this.refs.itemName.focus();
  }
  onSubmit(event) {
    event.preventDefault();
    var newItemValue = this.refs.itemName.value;
    
    if(newItemValue) {
      this.props.addItem({newItemValue});
      this.refs.form.reset();
    }
  }
  render () {
    return (
      <form ref="form" onSubmit={this.onSubmit} className="form-inline">
        <input type="text" ref="itemName" className="form-control" placeholder="Add admin"/>
        <button type="submit" variant="primary" className="btn btn-primary">Add</button> 
      </form>
    );   
  }
}
  
class AdminHeader extends React.Component {
  render () {
    return <h1>Admins</h1>;
  }
}
  
class Admins extends React.Component {
  getAdmins = async () => {
    try {
      const response = await API.get('streamercampapi', '/options/admins');
      let tempAdminsArray = [];
      for (var i=0; i < response[0].OptionsValue.length; i++) {
        tempAdminsArray.push({index: i, value: response[0].OptionsValue[i]})
      }
      admins = tempAdminsArray;
      this.setState({admins: admins});
      this.forceUpdate();
    } catch(err) {
      this.error();
    }
  }
  updateAdmins = async (admins) => {
    try {
      let adminsArray = [];
      for (let i = 0; i < admins.length; i++) {
        adminsArray.push(admins[i].value);
      }
      const response = await API.post('streamercampapi', '/options', {
        headers: {
          'Authorization': 'Bearer ' + this.props.auth.getIdToken()
        },
      body: {
        OptionsKey: 'admins',
        OptionsValue: adminsArray
      }});
      this.success();
    } catch(err) {
      this.error();
    }
  }
  constructor (props) {
    super(props);
    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.state = {admins: admins};
  }
  addItem(todoItem) {
    admins.unshift({
      index: admins.length+1, 
      value: todoItem.newItemValue
    });
    this.setState({admins: admins});
  }
  removeItem (itemIndex) {
    admins.splice(itemIndex, 1);
    this.setState({admins: admins});
  }
  componentDidMount(){
    this.getAdmins();
  }
  success = () => {
    toast.success("Success!", {
      position: toast.POSITION.TOP_CENTER
    })
  }
  error = () => {
    toast.error("An error occured!", {
      position: toast.POSITION.TOP_CENTER
    })
  }
  render() {
    if (!this.props.auth.getIdToken()) {
      return null;
    }
    return (
      <div id="main">
        <AdminHeader />
        <AdminList items={this.state.admins} removeItem={this.removeItem}/>
        <AdminForm addItem={this.addItem} />
        <br/>
        <Button
        variant="primary" className="btn btn-primary"
        onClick={() => this.updateAdmins(admins)}
        > Save
        </Button>
        <ToastContainer/>
      </div>
    );
  }
}



export default Admin;


