
import React, { Component } from 'react';
import StreamlabsMerch1 from './images/streamlabs-merch-1.png';
import StreamlabsMerch2 from './images/streamlabs-merch-2.png';
import StreamlabsMerch3 from './images/streamlabs-merch-3.png';

class Merch extends Component {

    render() {
      return (
        <div id="content" className="content">
          <div className="merch-container white-container">
            <div className="merch-text">Come support your favorite team and Streamer Camp by buying some merch! The two teams (SleightlyFusical and BoxyRae) are attempting to sell more merch than the other team so come support your favorite team here! On the final day of Streamer Camp, the team that sold the most merch will win. All proceeds will go towards future Streamer Camps <span role="img" aria-label="smile">😊</span>. Thank you all for your support!</div>
            <div className="row">
              <div className="col-sm-4">
                <a href="https://streamlabs.com/fuslie/#/merch" target="_blank" rel="noopener noreferrer">
                  <div className="sponsor-image merch-image" style={{paddingTop: '0%', paddingBottom: '0%'}}>
                    <img style={{width: '100%'}} src={StreamlabsMerch1} alt="Team SleightlyFusical"/>
                  </div>
                </a>
              </div>
              <div className="col-sm-4">
                <a href="https://streamlabs.com/fuslie/#/merch" target="_blank" rel="noopener noreferrer">
                  <div className="sponsor-image merch-image" style={{paddingTop: '0%', paddingBottom: '0%'}}>
                    <img style={{width: '100%'}} src={StreamlabsMerch3} alt="Streamer Camp"/>
                  </div>
                </a>
              </div>
              <div className="col-sm-4">
                <a href="https://streamlabs.com/fuslie/#/merch" target="_blank" rel="noopener noreferrer">
                  <div className="sponsor-image merch-image" style={{paddingTop: '0%', paddingBottom: '0%'}}>
                    <img style={{width: '100%'}} src={StreamlabsMerch2} alt="Team BoxyRae"/>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

export default Merch;



            