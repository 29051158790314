import React, { Component } from 'react';
import Amplify, { API } from 'aws-amplify';
import Moment from 'react-moment';
import awsmobile from './aws-exports';
import './App.css';
//import Banner from './images/banner.png'
import Banner from './images/StreamerCampLogo.png'
import StreamerCampBadge from './images/StreamerCampBadge3.png'
import moment from 'moment';
import {Link} from 'react-router-dom'
import ScrollToBottom from 'react-scroll-to-bottom';
import LiveBlog from './LiveBlog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import boxbox from './images/boxbox.jpg'
import fuslie from './images/fuslie.jpg'
import sleightlymusical from './images/sleightlymusical.jpg'
import valkyrae from './images/valkyrae.jpg'

library.add(fab)

Amplify.configure(awsmobile);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clips : [],
      vods : [],
      pictures : [],
      liveblog : [],
      announcement: null,
      leaderboard: {"team1":{"name": "SleightlyFusical", "day1": 0, "day2": 0, "day3": 0, "day4": 0}, "team2":{"name": "Boxyrae", "day1": 0, "day2": 0, "day3": 0, "day4": 0}},
    };
  }

  componentDidMount() {
    this.getClips();
    this.getVODs();
    this.getPictures();

    this.loadLive();
    this.interval = setInterval(() => this.loadLive(), 120000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  loadLive() {
    this.getLiveBlog();
    this.getLiveLeaderboard();
    this.getAnnouncement();
  }

  getClips = async () => {
    const response = await API.get('streamercampapi', '/media/Clip?limit=4');
    this.setState({clips: response});
  } 

  getPictures = async () => {
    const response = await API.get('streamercampapi', '/media/Picture?limit=4');
    this.setState({pictures: response});
  } 

  getVODs = async () => {
    const response = await API.get('streamercampapi', '/media/VOD?limit=4');
    this.setState({vods: response});
  }

  getLiveBlog = async () => {
    const response = await API.get('streamercampapi', '/blogs/Live Blog');
    this.setState({liveblog: response});
  }

  getLiveLeaderboard = async () => {
    const response = await API.get('streamercampapi', '/options/leaderboard', {
      headers: {
        'Authorization': 'Bearer ' + this.props.auth.getIdToken()
      }});
    if (response && response.length > 0) {
      let leaderboard = response[0].OptionsValue;
      this.setState({leaderboard: leaderboard});
    }
  }

  getAnnouncement = async () => {
    const response = await API.get('streamercampapi', '/blogs/Announcement', {
      headers: {
        'Authorization': 'Bearer ' + this.props.auth.getIdToken()
      }});
    if (response && response.length > 0) {
      let announcement = response[0].BlogContent;
      this.setState({announcement: announcement});
    }
  }

  render() {
    var vods = this.state.vods.map((vod, key) =>
      <div key={key} className="col-md-3">
        <a href={vod.MediaLink} target="_blank" rel="noopener noreferrer" className="home-video-link">
          <div className="home-video-container">
            <img alt="" className="home-thumbnail sand-frame" src={vod.MediaThumbnail}/>
            <div className="home-thumbnail-description">
              <strong>{vod.MediaTitle}</strong>
            </div>
          </div>
        </a>
      </div>
    );

    var clips = this.state.clips.map((clip, key) =>
      <div key={key} className="col-md-3">
        <a href={clip.MediaLink} target="_blank" rel="noopener noreferrer" className="home-video-link">
          <div className="home-video-container">
            <img alt="" className="home-thumbnail sand-frame" src={clip.MediaThumbnail}/>
            <div className="home-thumbnail-description">
              <strong>{clip.MediaTitle}</strong>
            </div>
          </div>
        </a>
      </div>
    );

    var pictures = this.state.pictures.map((picture, key) =>
      <div key={key} className="col-md-3">
        <a href={picture.MediaLink} target="_blank" rel="noopener noreferrer" className="home-video-link">
          <div className="home-video-container">
            <img alt="" className="home-thumbnail sand-frame" src={picture.MediaThumbnail}/>
            <div className="home-thumbnail-description">
              <strong>{picture.MediaTitle}</strong>
            </div>
          </div>
        </a>
      </div>
    );

    var liveblog = [];
    var date=null;
    
    for(let i = 0; i < this.state.liveblog.length; i++) {
      let orderDate = moment(this.state.liveblog[i].BlogTime).format('MMMM Do, YYYY');
      if (date !== orderDate) {
        liveblog.push(
          <div className="chat-day"><strong>{orderDate}</strong></div>
        )
        date = orderDate;
      }
      // if (<Moment local format="YYYY-DD-MM" date={this.state.liveblog[i].BlogTime}/>)
      liveblog.push(
        <div key={i} className="chat-message">
        <div>
          <Moment local format="h:mm zz" date={this.state.liveblog[i].BlogTime}/>
          <img alt="Broadcaster" aria-label="Broadcaster badge" class="chat-badge" src="https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/4" srcset="https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/1 1x, https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/2 2x, https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/3 4x"/>
          <img alt="Badge" aria-label="Verified badge" class="chat-badge" src={StreamerCampBadge} ></img>
          <img alt="Verified" aria-label="Verified badge" class="chat-badge" src="https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/4" srcset="https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/1 1x, https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/2 2x, https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/3 4x"></img>
          <span className="chat-user">StreamerCampTV: </span><span dangerouslySetInnerHTML={{ __html : this.state.liveblog[i].BlogContent.replace("<p>", "").replace("</p>", "")}}></span>
        </div>
      </div>
      )
    }

    var team1Total = parseFloat(this.state.leaderboard.team1.day1) + parseFloat(this.state.leaderboard.team1.day2) + parseFloat(this.state.leaderboard.team1.day3) + parseFloat(this.state.leaderboard.team1.day4);
    var team2Total = parseFloat(this.state.leaderboard.team2.day1) + parseFloat(this.state.leaderboard.team2.day2) + parseFloat(this.state.leaderboard.team2.day3) + parseFloat(this.state.leaderboard.team2.day4);

    var leaderboardhtml =
    <tbody>
      {team1Total >= team2Total &&
      <tr>
        <td style={{wordBreak: 'break-word'}}>{this.state.leaderboard.team1.name}</td>
        <td>{this.state.leaderboard.team1.day1}</td>
        <td>{this.state.leaderboard.team1.day2}</td>
        <td>{this.state.leaderboard.team1.day3}</td>
        <td>{this.state.leaderboard.team1.day4}</td>
        <td>{team1Total}</td>
      </tr>
      }
      <tr>
        <td style={{wordBreak: 'break-word'}}>{this.state.leaderboard.team2.name}</td>
        <td>{this.state.leaderboard.team2.day1}</td>
        <td>{this.state.leaderboard.team2.day2}</td>
        <td>{this.state.leaderboard.team2.day3}</td>
        <td>{this.state.leaderboard.team2.day4}</td>
        <td>{team2Total}</td>
      </tr>
      
      {team1Total < team2Total &&
      <tr>
        <td style={{wordBreak: 'break-word'}}>{this.state.leaderboard.team1.name}</td>
        <td>{this.state.leaderboard.team1.day1}</td>
        <td>{this.state.leaderboard.team1.day2}</td>
        <td>{this.state.leaderboard.team1.day3}</td>
        <td>{this.state.leaderboard.team1.day4}</td>
        <td>{team1Total}</td>
      </tr>
      }
    </tbody>;


    return (
        <div id="content" className="content">
            <img alt="" className="banner" src={Banner}/>
            {this.state.announcement && <div class="home-subsection-banner announcement-banner" dangerouslySetInnerHTML={{__html: this.state.announcement.replace(/<a/g, '<a target="_blank"')}}></div>}
            <div className="section sand-background">
              <div className="row">
                <div className="col-md-12">
                  <div className="home-subsection-banner">
                    About
                  </div>
                  <div className="regular-font background-semi-transparent">
                    Over 800 streamers from around the world applied for Streamer Camp and after looking over their video entries, Twitch streams, and doing interviews with them, four hosts (<a className="info-link" href="https://twitter.com/fuslie">fuslie</a>, <a className="info-link" href="https://twitter.com/boxbox">boxbox</a>, <a className="info-link" href="https://twitter.com/Valkyrae">Valkyrae</a>, and <a className="info-link" href="https://twitter.com/THEalbertchang">sleightlymusical</a>) have chosen their top eight to fly down to Los Angeles, CA to live together for a week and compete in a series of events to hone their skills as streamers, content creators, and create some incredible memories and content together. Streamed live for four days (June 6th to 9th)!
                    <br/>
                    <div className="social-links">
                      <a className="info-link" href="https://twitter.com/streamercampla" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']}  size="2x"/></a>
                      <a className="info-link" href="https://www.instagram.com/streamercamp/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']}  size="2x"/></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                    <div className="home-subsection-banner">
                      <div class="subsection-header-left">VODs</div><div class="subsection-header-right"><Link to='/vods'>→ View All</Link></div>
                    </div>
                    <div>
                      <div className="row">
                        { vods }
                      </div>
                    </div>
                    <div className="home-subsection-banner">
                      <div class="subsection-header-left">Clips</div><div class="subsection-header-right"><Link to='/clips'>→ View All</Link></div>
                    </div>
                    <div>
                      <div className="row">
                        { clips }
                      </div>
                    </div>
                    <div className="home-subsection-banner">
                      <div class="subsection-header-left">Pictures</div><div class="subsection-header-right"><Link to='/pictures'>→ View All</Link></div>
                    </div>
                    <div>
                      <div className="row">
                        { pictures }
                      </div>
                    </div>
                </div>
                <div className="col-md-4">
                  <div className="home-subsection-banner">
                    Live Blog
                  </div>
                  {/* <div className="regular-font background-chat">
                    <div className="chat-header">
                      <div className="chat-header-left">Live Blog</div>
                      <div className="chat-header-right">
                        <Link to='/recaps'><button>View All</button>
                        </Link></div>
                    </div>
                    <div className="chat-body">
                    <ScrollToBottom>
                      <div data-a-target="chat-welcome-message" class="chat-message chat-message-moderation"><span class="">Welcome to streamercamp.tv!</span></div>
                      { liveblog }
                      <div style={{ float:"left", clear: "both" }}
                          ref={(el) => { this.messagesEnd = el; }}>
                      </div>
                    </ScrollToBottom>
                    </div>
                  </div> */}
                  <LiveBlog homepage={true}/>
                </div>
              </div>
              <div className="row">
                  <div className="col-md-12">
                    <div className="home-subsection-banner">
                      Live Leaderboard
                    </div>
                    <table class="table leaderboard-table background-semi-transparent">
                      <thead>
                        <tr>
                          <th scope="col">Team</th>
                          <th scope="col">Day 1</th>
                          <th scope="col">Day 2</th>
                          <th scope="col">Day 3</th>
                          <th scope="col">Day 4</th>
                          <th scope="col">Total</th>
                        </tr>
                        </thead>
                        { leaderboardhtml }
                      </table>
                  </div>
              </div>
              <div className="row">
                  <div className="col-md-12">
                    <div className="home-subsection-banner">
                      Hosts
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-sm-6">
                        <div class="host-picture-container sand-frame">
                          <img alt="boxbox" className="host-picture" src={boxbox}/>
                          <span className="host-name">boxbox</span>
                        </div>
                        <div className="host-info regular-font background-semi-transparent sand-frame rounded">
                          <table className="table-stick-bottom">
                              <tbody>
                                <tr>
                                  <td valign="top">
                                    <div>Hi! my name is <strong>Albert</strong>. I'm 22 years old and I like Riven and sometimes do weird things outside.</div>
                                  </td>
                                </tr>
                                <tr>
                                  <td valign="bottom" className="table-stick-bottom-lower-td">
                                    <div className="social-links">
                                      <a className="info-link" href="https://www.twitch.tv/boxbox" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitch']}  size="2x"/> </a>
                                      <a className="info-link" href="https://twitter.com/boxbox" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']}  size="2x"/> </a>
                                      <a className="info-link" href="https://www.youtube.com/user/albertsunzheng" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']}  size="2x"/> </a>
                                      <a className="info-link" href="https://www.instagram.com/notboxbox" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']}  size="2x"/> </a>
                                      <a className="info-link" href="https://www.facebook.com/LoLBoxbox" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'facebook-f']} size="2x"/> </a>
                                    </div>
                                  </td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <div class="host-picture-container sand-frame">
                          <img alt="fuslie" className="host-picture" src={fuslie}/>
                          <span className="host-name">fuslie</span>
                        </div>
                        <div className="host-info regular-font background-semi-transparent sand-frame rounded">
                          <table className="table-stick-bottom">
                              <tbody>
                                <tr>
                                  <td valign="top">
                                    <div>Hi my name is Leslie! I'm a variety streamer who mostly likes to chill with her Twitch chat and making CONTENT :D. I like games, friends, singing, and my fiance Edison!</div>
                                  </td>
                                </tr>
                                <tr>
                                  <td valign="bottom" className="table-stick-bottom-lower-td">
                                    <div className="social-links">
                                      <a className="info-link" href="https://www.twitch.tv/fuslie" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitch']}  size="2x"/></a>
                                      <a className="info-link" href="https://twitter.com/fuslie" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']}  size="2x"/></a>
                                      <a className="info-link" href="https://www.youtube.com/fuslie" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']}  size="2x"/></a>
                                      <a className="info-link" href="https://www.instagram.com/fuslie" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']}  size="2x"/></a>
                                      <a className="info-link" href="https://www.facebook.com/fuslie" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'facebook-f']} size="2x"/></a>
                                    </div>
                                  </td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <div class="host-picture-container sand-frame">
                          <img alt="sleightlymusical" className="host-picture" src={sleightlymusical}/>
                          <span className="host-name">sleightlymusical</span>
                        </div>
                        <div className="host-info regular-font background-semi-transparent sand-frame rounded">
                          <table className="table-stick-bottom">
                              <tbody>
                                <tr>
                                  <td valign="top">
                                    <div>I’m a sleightly musical magician and musician.</div>
                                  </td>
                                </tr>
                                <tr>
                                  <td valign="bottom" className="table-stick-bottom-lower-td">
                                    <div className="social-links">
                                      <a className="info-link" href="https://www.twitch.tv/sleightlymusical" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitch']}  size="2x"/></a>
                                      <a className="info-link" href="https://twitter.com/thealbertchang" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']}  size="2x"/></a>
                                      <a className="info-link" href="https://www.youtube.com/sleightlymusical" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']}  size="2x"/></a>
                                      <a className="info-link" href="https://www.instagram.com/sleightlymusical" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']}  size="2x"/></a>
                                      <a className="info-link" href="https://www.facebook.com/sleightlymusical" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'facebook-f']} size="2x"/></a>
                                    </div>
                                  </td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <div class="host-picture-container sand-frame">
                          <img alt="Valkyrae" className="host-picture" src={valkyrae}/>
                          <span className="host-name">Valkyrae</span>
                        </div>
                        <div className="host-info regular-font background-semi-transparent sand-frame rounded">
                          <table className="table-stick-bottom">
                              <tbody>
                                <tr>
                                  <td valign="top">
                                    <div>Hello!! My name's Rae and I'm a fulltime partnered streamer on Twitch! I used to be a variety streamer until I started playing Fortnite and ever since.. I've been playing Fortnite ONLY LOL.</div>
                                  </td>
                                </tr>
                                <tr>
                                  <td valign="bottom" className="table-stick-bottom-lower-td">
                                    <div className="social-links">
                                      <a className="info-link" href="https://www.twitch.tv/valkyrae" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitch']}  size="2x"/></a>
                                      <a className="info-link" href="https://twitter.com/valkyrae" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']}  size="2x"/></a>
                                      <a className="info-link" href="https://www.youtube.com/channel/UCWxlUwW9BgGISaakjGM37aw" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']}  size="2x"/></a>
                                      <a className="info-link" href="https://www.instagram.com/valkyrae" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']}  size="2x"/></a>
                                    </div>
                                  </td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
      </div>
    );
  }
}

export default App;
