import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AdminLeaderboard extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        leaderboard : {"team1":{"name": "SleightlyFusical", "day1": 0, "day2": 0, "day3": 0, "day4": 0}, "team2":{"name": "Boxyrae", "day1": 0, "day2": 0, "day3": 0, "day4": 0}},
        team1name: null,
        team2name: null,
        team1day1: null,
        team1day2: null,
        team1day3: null,
        team1day4: null,
        team2day1: null,
        team2day2: null,
        team2day3: null,
        team2day4: null,
        team1total: null,
        team2total: null,
      };
    }
    success = () => {
      toast.success("Success!", {
        position: toast.POSITION.TOP_CENTER
      })
    }
    error = () => {
      toast.error("An error occured!", {
        position: toast.POSITION.TOP_CENTER
      })
    }
    componentDidMount(){
        this.listAll();
    }
    listAll = async () => {
      try {
        const response = await API.get('streamercampapi', '/options/leaderboard', {
          headers: {
            'Authorization': 'Bearer ' + this.props.auth.getIdToken()
          }});
        if (response && response.length > 0) {
          let leaderboard = response[0].OptionsValue;
          this.setState({leaderboard: leaderboard});
          this.setState({team1name: leaderboard.team1.name});
          this.setState({team2name: leaderboard.team2.name});
          this.setState({team1day1: leaderboard.team1.day1});
          this.setState({team1day2: leaderboard.team1.day2});
          this.setState({team1day3: leaderboard.team1.day3});
          this.setState({team1day4: leaderboard.team1.day4});
          this.setState({team2day1: leaderboard.team2.day1});
          this.setState({team2day2: leaderboard.team2.day2});
          this.setState({team2day3: leaderboard.team2.day3});
          this.setState({team2day4: leaderboard.team2.day4});
        }
        this.calculateTeam1Total();
        this.calculateTeam2Total();
      } catch(err) {
        this.error();
      }
    }
    saveLeaderboard = async () => {
      try {
        await API.post('streamercampapi', '/options', {
          headers: {
            'Authorization': 'Bearer ' + this.props.auth.getIdToken()
          },
          body: {
            OptionsKey: 'leaderboard',
            OptionsValue: {'team1':{'name': this.state.team1name, 'day1':  parseFloat(this.state.team1day1), 'day2':  parseFloat(this.state.team1day2), 'day3':  parseFloat(this.state.team1day3), 'day4':  parseFloat(this.state.team1day4)}, 'team2':{'name': this.state.team2name, 'day1':  parseFloat(this.state.team2day1), 'day2':  parseFloat(this.state.team2day2), 'day3':  parseFloat(this.state.team2day3), 'day4':  parseFloat(this.state.team2day4)}},
          }});
        this.success();
      } catch(err) {
        this.error();
      }
    }
    calculateTeam1Total = () => {
      this.setState({team1total: parseFloat(this.state.team1day1) + parseFloat(this.state.team1day2) + parseFloat(this.state.team1day3) + parseFloat(this.state.team1day4)});
    }
    calculateTeam2Total = () => {
      this.setState({team2total: parseFloat(this.state.team2day1) + parseFloat(this.state.team2day2) + parseFloat(this.state.team2day3) + parseFloat(this.state.team2day4)});
    }
    handleTeam1NameChange = (e) => {
      this.setState({team1name: e.target.value});
    } 
    handleTeam2NameChange = (e) => {
      this.setState({team2name: e.target.value});
    } 
    handleTeam1Day1Change = (e) => {
      this.setState({team1day1: e.target.value}, () => {
        this.calculateTeam1Total();
      });
    } 
    handleTeam1Day2Change = (e) => {
      this.setState({team1day2: e.target.value}, () => {
        this.calculateTeam1Total();
      });
    } 
    handleTeam1Day3Change = (e) => {
      this.setState({team1day3: e.target.value}, () => {
        this.calculateTeam1Total();
      });
    } 
    handleTeam1Day4Change = (e) => {
      this.setState({team1day4: e.target.value}, () => {
        this.calculateTeam1Total();
      });
    } 
    handleTeam2Day1Change = (e) => {
      this.setState({team2day1: e.target.value}, () => {
        this.calculateTeam2Total();
      });
    } 
    handleTeam2Day2Change = (e) => {
      this.setState({team2day2: e.target.value}, () => {
        this.calculateTeam2Total();
      });
    } 
    handleTeam2Day3Change = (e) => {
      this.setState({team2day3: e.target.value}, () => {
        this.calculateTeam2Total();
      });
    } 
    handleTeam2Day4Change = (e) => {
      this.setState({team2day4: e.target.value}, () => {
        this.calculateTeam2Total();
      });
    } 
  render() {
      if (!this.props.auth.getIdToken()) {
        return null;
      }
      var leaderboardhtml =
      <tbody>
        <tr>
          <td><input id='team1name' type="string" onChange={this.handleTeam1NameChange} value={this.state.team1name}/></td>
          <td><input id='team1-day1' type="number" onChange={this.handleTeam1Day1Change} value={this.state.team1day1}/></td>
          <td><input id='team1-day2' type="number" onChange={this.handleTeam1Day2Change} value={this.state.team1day2}/></td>
          <td><input id='team1-day3' type="number" onChange={this.handleTeam1Day3Change} value={this.state.team1day3}/></td>
          <td><input id='team1-day4' type="number" onChange={this.handleTeam1Day4Change} value={this.state.team1day4}/></td>
          <td>{this.state.team1total}</td>
        </tr>
        <tr>
          <td><input id='team2name' type="string" onChange={this.handleTeam2NameChange} value={this.state.team2name}/></td>
          <td><input id='team2-day1' type="number" onChange={this.handleTeam2Day1Change} value={this.state.team2day1}/></td>
          <td><input id='team2-day2' type="number" onChange={this.handleTeam2Day2Change} value={this.state.team2day2}/></td>
          <td><input id='team2-day3' type="number" onChange={this.handleTeam2Day3Change} value={this.state.team2day3}/></td>
          <td><input id='team2-day4' type="number" onChange={this.handleTeam2Day4Change} value={this.state.team2day4}/></td>
          <td>{this.state.team2total}</td>
        </tr>
      </tbody>;
      return (
        <div>
          <h2>Leaderboard</h2>
          <Button
            variant="primary" className="btn btn-primary"
            onClick={() => this.listAll()}
          >
            Refresh
          </Button>
          <table className="table admin-table leaderboard-table background-semi-transparent">
          <thead>
            <tr>
              <th scope="col">Team</th>
              <th scope="col">Day 1</th>
              <th scope="col">Day 2</th>
              <th scope="col">Day 3</th>
              <th scope="col">Day 4</th>
              <th scope="col">Total</th>
            </tr>
            </thead>
              { leaderboardhtml }
          </table>
          <Button
            variant="primary" className="btn btn-primary"
            onClick={() => this.saveLeaderboard()}
          >
            Save Leaderboard
          </Button>
          <ToastContainer/>
        </div>
      );
  }
}

export default AdminLeaderboard;



            