import React from 'react';

import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

class Header extends React.Component {
  state = { 
    navExpanded: false,
   }

  setNavExpanded= (expanded) => {
    this.setState({ navExpanded: expanded });
  }

  closeNav = () =>  {
    this.setState({ navExpanded: false });
  }

  render() {
    return (
      <Navbar fixedTop onToggle={this.setNavExpanded} expanded={this.state.navExpanded}>
        <Navbar.Header className="nav-header">
          <Navbar.Brand>
            
            <LinkContainer to="/">
            <a href="/">
              <strong>Streamer Camp</strong>
            </a>
            </LinkContainer>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav onSelect={this.closeNav}>
            <LinkContainer to="/menu">
              <NavItem className="nav-item">
                <div className="nav-link-container">
                  <div className="nav-link">Menu</div>
                </div>
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/recaps">
              <NavItem className="nav-item">
                <div className="nav-link-container">
                  <div className="nav-link">Recaps</div>
                </div>
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/contestants">
              <NavItem className="nav-item">
                <div className="nav-link-container">
                  <div className="nav-link">Contestants</div>
                </div>
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/staff">
              <NavItem className="nav-item">
                <div className="nav-link-container">
                  <div className="nav-link">Staff</div>
                </div>
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/sponsors">
              <NavItem className="nav-item">
                <div className="nav-link-container">
                  <div className="nav-link">Sponsors</div>
                </div>
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/merch">
              <NavItem className="nav-item">
                <div className="nav-link-container">
                  <div className="nav-link">Merch</div>
                </div>
              </NavItem>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}


export default Header;