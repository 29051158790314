import React, { Component } from 'react';
import { API } from 'aws-amplify';


class VODs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clips: [],
      pictures: [],
      vods: [],
    };
  }
  componentDidMount() {
    this.getPictures();
  }
  getPictures = async () => {
    const response = await API.get('streamercampapi', '/media/Picture');
    this.setState({pictures: response});
  }

  render() {

    var pictures = this.state.pictures.map((picture, key) =>
      <div key={key} className="col-md-3">
        <a href={picture.MediaLink} target="_blank" className="home-video-link" rel="noopener noreferrer">
          <div className="home-video-container">
            <img className="home-thumbnail sand-frame" src={picture.MediaThumbnail} alt=""/>
            <div className="home-thumbnail-description">
              <strong>{picture.MediaTitle}</strong>
            </div>
          </div>
        </a>
      </div>
    );

    return (
        <div id="content" className="content">
        <div className="section sand-background">
              <div className="row">
                  <div className="col-md-12">
                    <div className="home-subsection-banner">
                      Pictures
                    </div>
                    <div className="row">
                      { pictures }
                    </div>
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

export default VODs;
