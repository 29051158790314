
import React, { Component } from 'react';
import YouTube from 'react-youtube';
import Airzach from './images/airzach-profile.jpg';
import Exlacraft from './images/exlacraft-profile.jpg';
import Fasffy from './images/fasffy-profile.jpg';
import HelloMeganLee from './images/hellomeganlee-profile.jpg';
import KristoferYee from './images/kristoferyee-profile.jpg';
import MitsukiBb from './images/mitsukibb-profile.jpg';
import Negaoryx from './images/negaoryx-profile.jpg';
import TJSmith from './images/tjsmith-profile.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(fab)

class Contestants extends Component {
  
  constructor(props) {
    super(props);
    this.contestant = props.match.params.contestant;
  }

    render() {
      var header;
      var pic;
      var name;
      var location;
      var bioFavColor;
      var bioFavFood;
      var bioFavMusic;
      var bioInterests;
      var bioAbout;
      var top32;
      var top16;
      var top8;
      var twitchUrl;
      var twitterUrl;
      var youtubeUrl;
      var instagramUrl;
      var facebookUrl;
      if (this.contestant.toLowerCase() === 'airzach') {
        header = "airzach";
        pic = Airzach;
        name = "Zach";
        location = "Los Angeles, California";
        bioFavColor = "Black, White, Red";
        bioFavFood = "Pepperoni Pizza, Sushi, Coke Zero/Diet Coke/Boba, Veggie Chips/Skittles";
        bioFavMusic = "Pop, Rock, Jonas Brothers, Linkin Park, Rise Against, Fallout Boy, The Weekend, Ariana Grande, Justin Bieber, Kanye West, any and all Kpop";
        bioInterests = "Anything Disney, Audio recording, singing, and mixing music, Naruto/most anime, I love cars and know a decent amount about Teslas and Lamborghinis.";
        bioAbout = "Hello! My name is Zach Aguilar but I go by airzach online. I've been acting for most of my life and have appeared in haircut magazines, commercials, and short films when I was younger. I'm currently a voice actor that works in anime, video games, and commercials. I've recently started pursuing streaming and love to play video games. I would like to build my audience online and establish myself as a content creator.";
        top32 = "YG1dVGvtlEc";
        top16 = "PPc8rXZuIJo";
        top8 = "1734unynokc";
        twitchUrl = 'https://www.twitch.tv/airzach';
        twitterUrl = 'https://twitter.com/airzach';
        youtubeUrl = null;
        instagramUrl = 'https://www.instagram.com/zachaguilar/';
        facebookUrl = 'https://www.facebook.com/ZachAguilarVoiceActor/';
      } else if (this.contestant.toLowerCase() === 'exla') {
        header = "Exla";
        pic = Exlacraft;
        name = "Alex";
        location = "Toronto, Ontario";
        bioFavColor = "Blue, Orange";
        bioFavFood = "Ramen, Thai food, In and out!!, Any type of sweets (fav: Sour Patch Kids), Crackers, Mangos, Ginger ale, Apple Juice, Orange Juice etc.";
        bioFavMusic = "Mumford and Sons, Coldplay, Jack Johnson, Queen, Rush. (fav genre/ type is 80s music)";
        bioInterests = "I love filming/ taking photos, longboarding, Skiing, Slack-lining, Go Karting, Dodge Ball, trampoline parks and more.";
        bioAbout = "Hi! Im Alex, also known as Alexa or Xelacraft. I enjoy streaming power points on twitch, watching movies and dancing terribly. Im 20 years old and am finishing my course at Toronto Film School in film production. My friends would describe me as hyperactive, creative, annoying and talkative.";
        top32 = "Z0BcDqlNVpQ";
        top16 = "4d9b7aQHrr0";
        top8 = "5hHXJo97Oy4";
        twitchUrl = 'https://www.twitch.tv/exla';
        twitterUrl = 'https://twitter.com/exlacraft';
        youtubeUrl = 'https://www.youtube.com/channel/UC3PO5hdfJFEJhXBMtkXsrdQ';
        instagramUrl = 'https://www.instagram.com/alexxski/';
        facebookUrl = null;
      } else if (this.contestant.toLowerCase() === 'fasffy') {
        header = "Fasffy";
        pic = Fasffy;
        name = "Fasffy";
        location = "Santa Monica, California";
        bioFavColor = "All!";
        bioFavFood = "I love snacks, no pref (also don't know a lot of american snacks tbh...)!";
        bioFavMusic = "I enjoy all music, really like indie";
        bioInterests = "Like the Avengers, Marvel TV series are POG, can't solve a rubik's cube, dig 80's music. Generally enjoy being creative (painting, making costumes etc) and trying new things, never really ecstatic to do math though";
        bioAbout = "I cant get away from video games or esports. From around the age of 9 I was already too far down the rabbit hole. At 20 I tried to cut all ties to gaming because I thought I had to become a \"real adult\" and moved to a whole other country in hopes to become this adult. I'm glad I did because I quickly realized that wasn't going to happen and now fully pursue a life revolved around any aspect of video gaming, content creation and esports without any guilt.";
        top32 = "dwSzjuokSF4";
        top16 = "TZSLv9OZiB8";
        top8 = "XTxg7SNdE0Q";
        twitchUrl = 'https://www.twitch.tv/fasffy';
        twitterUrl = 'https://twitter.com/Fasffy';
        youtubeUrl = 'https://www.youtube.com/c/fasffy';
        instagramUrl = 'https://www.instagram.com/fasffy/';
        facebookUrl = 'https://www.facebook.com/fasffy';
      } else if (this.contestant.toLowerCase() === 'hellomeganlee') {
        header = "HelloMeganLee";
        pic = HelloMeganLee;
        name = "Megan";
        location = "Los Angeles, California";
        bioFavColor = "Pink, Light Purple, Baby Blue, Neons, Black";
        bioFavFood = "Pizza, tacos, pasta, noodles I LOVE CARBS. I love gummie bears/sour belts and hot cheetos.";
        bioFavMusic = "POP! i also love edm but with much pop elements :) im a big fan of jazz/soul too (sinatra, fitzgerald, stevie wonder, michael jackson, lauren hill)";
        bioInterests = "I like to draw, watching movies, dance, and I'm obsessed with sailor moon and disney! ";
        bioAbout = "I’m a singer-songwriting actress that’s been streaming on Twitch for less than half a year. I love to make people smile, laugh and feel loved. I love dogs and sometimes cats. As much as i love to eat, I value the importance of exercise and overall health. I love to experiment, be spontaneous and most of all have fun!";
        top32 = "h78ybYWfsvg";
        top16 = "rikl1MhYED4";
        top8 = "r3P4MlKYmWg";
        twitchUrl = 'https://www.twitch.tv/hellomeganlee';
        twitterUrl = 'https://twitter.com/hellomeganlee';
        youtubeUrl = 'https://www.youtube.com/channel/UCwfw7WUGH5HRP_AQTU_bSHA';
        instagramUrl = 'https://www.instagram.com/hellomeganlee/';
        facebookUrl = 'https://www.facebook.com/hellomeganlee';
      } else if (this.contestant.toLowerCase() === 'kristoferyee') {
        header = "KristoferYee";
        pic = KristoferYee;
        name = "Kristofer";
        location = "Las Vegas, Nevada";
        bioFavColor = "Grey, Black, Purple";
        bioFavFood = "Boba";
        bioFavMusic = "Rap, EDM, Hip Hop, Trap, (anything you can dance to)";
        bioInterests = "I really like League, dancing, pretty much any art form, learning from you guys, and having deeper conversation. Talking about life and such";
        bioAbout = "I like doing a lot of things and learning from people who know better than me. I've spent so much time behind the camera and now I kinda want to be in front of it. I  I'm very go with the flow and am excited to do pretty much everything. I haven't had any good friends for a while so I really enjoy being with people or hanging out.";
        top32 = "p_wnLe5s0tI";
        top16 = "kYbfVupQW68";
        top8 = "RwBu3t8mZI4";
        twitchUrl = 'https://www.twitch.tv/kristoferyee';
        twitterUrl = 'https://twitter.com/KristoferYee';
        youtubeUrl = 'https://www.youtube.com/kristoferyee';
        instagramUrl = 'https://www.instagram.com/kristoferyee';
        facebookUrl = 'https://www.facebook.com/KristoferYeeVideos';
      } else if (this.contestant.toLowerCase() === 'mitsukibb') {
        header = "MitsukiBb";
        pic = MitsukiBb;
        name = "Mitsu";
        location = "Toronto, Ontario";
        bioFavColor = "Mint Green/Blue, Pastel Blue";
        bioFavFood = "Dim Sum, Pho, anything with cheese tbh, Bubble Tea, Ketchup Chips, Sour Patch Kids, Nestea, Sprite";
        bioFavMusic = "K-Pop mostly (Girls Generation, Twice, Red Velvet), Ariana Grande";
        bioInterests = "MARVEL CINEMATIC UNIVERSE FANATIC, CAPTAIN AMERICA AND CHRIS EVANS, K-Pop, Anime, Speed Cup Stacking, Singing, Cosplayer, Drawing/Art, CUTE FLUFFY THINGS, Collecting Cute Stuff and Funko Pops, Collecting/Playing Board Games";
        bioAbout = "I was in a Science program in University for almost two years before I decided to take a break because I wanted to change programs/schools. In the end, I started doing stream full-time during my break from school since I've always dreamed of being a content creator. Over the years, I've dabbled in a lot of random skills and hobbies. I've taken piano classes, learned the trombone, done dance covers, had art classes (for painting and drawing), cosplayed, made miniature clay sculptures/charms,  tried speed stacking cups, learned how to use the chinese yoyo and a LOT more.";
        top32 = "0SbN_LO-HkY";
        top16 = "JheUVqig3BY";
        top8 = "VOB4z7q84KU";
        twitchUrl = 'https://www.twitch.tv/mitsukibb';
        twitterUrl = 'https://twitter.com/BbMitsuki';
        youtubeUrl = 'https://www.youtube.com/channel/UCSs1BjeaugoQJYFTBLLwD4A';
        instagramUrl = 'https://www.instagram.com/mitsukibb/';
        facebookUrl = null;
      } else if (this.contestant.toLowerCase() === 'negaoryx') {
        header = "negaoryx";
        pic = Negaoryx;
        name = "negaoryx";
        location = "Los Angeles, California";
        bioFavColor = "Black & Pastel Green/Blue/Pink";
        bioFavFood = "Veggies & pita chips + hummus, sparkling water, plain/salted popcorn, chocolate chip clif bars, blueberry bel vita biscuits :D";
        bioFavMusic = "I'm super easy! I like anything except super hardcore screamo stuff. I like anything from Frank Sinatra to Billie Eilish. Favorite artists include Acid Tongue, Better Oblivion Community Center, Los Campesinos!, Death Cab for Cutie, Ashe, & Voxtrot. ";
        bioInterests = "I love film! I am a big movie nerd & love watching/discussing films of all genres. I also love baking (I will happily bring ingredients to bake everyone homemade stuff) and also low key kinda love playing bartender, mixing drinks for my friends. I LOVE good craft beer & single malt scotch.";
        bioAbout = "negaoryx is a 20-something full-time streamer who loves story-driven single player games & simulation games. twitch partner, LA native, film nerd, theme park enthusiast & proud slytherin. ";
        top32 = "Y1GS7KEnFDA";
        top16 = "P97vzzAjeNs";
        top8 = "OXLRRvTnCQA";
        twitchUrl = 'https://www.twitch.tv/negaoryx';
        twitterUrl = 'https://twitter.com/negaoryx';
        youtubeUrl = 'https://www.youtube.com/channel/UCmyPx-c5d2L28YpOmfp2-Wg';
        instagramUrl = 'https://www.instagram.com/negaoryx/';
        facebookUrl = null;
      } else if (this.contestant.toLowerCase() === 'tjsmith') {
        header = "tjsmith";
        pic = TJSmith;
        name = "TJ";
        location = "Los Angeles, California";
        bioFavColor = "Blue / Dark Teal ";
        bioFavFood = "High protein, low carb, lots of veggies and ranch :) carrots and celery and broccoli are delish";
        bioFavMusic = "O wow ok, 1940’s, 50’s / 60’s, pop punk, Fall Out Boy all albums, EDM pop, Panic! At the Disco (new album mainly), Old Town Road remix, CHVRCHES, Zero 7, Tritonal, Taking Back Sunday, Glitch Mob, Elton John, Tobu, Sinatra, Bruno Mars";
        bioInterests = "Working out! Let’s do a group cardio or yoga session or something lmao. Hot yoga! Hahaha. Also BEAT SABER but like, silly custom songs like “You’re Welcome” from Moana and “Shake It Off” by T Swift. Also yes I love and have seen all of the Marvel MCU movies. And I’m a big Bojack Horseman fan. Ravenpuff.";
        bioAbout = "Originally from Texas and now living in Los Angeles, TJ started out on YouTube in 2009 with music videos, comedy sketches, and gaming lets plays. Over the years, he’s experienced the full spectrum of being a content creator on the internet, from working with critically-acclaimed music producer and YouTube sensation (and friend from college) Kurt Hugo Schneider, to working at Maker Studios  managing some of their top gaming talent, to creating his own viral series called Highway Singalongs, which were shown on local news stations around the world and led to a national car commercial with Nissan plus an appearance on The Voice. Since then, he's transitioned to variety streaming on Twitch with a focus on community interaction, gaming, and music. TJ considers himself a singer/songwriter/streamer type, and has been writing original songs since he was 7 years old. He currently has a debut album plus a handful of additional singles available on all streaming services, and hopes to be able to turn his songwriting passion into his main career while still maintaining a strong connection with his viewers on Twitch and YouTube. You can find him at @tjsmith on all major platforms.";
        top32 = "V1Udmc9Eg6I";
        top16 = "_Q_hHnMa9kI";
        top8 = "nmlucc3cStk";
        twitchUrl = 'https://www.twitch.tv/tjsmith';
        twitterUrl = 'https://twitter.com/tjsmith';
        youtubeUrl = 'https://www.youtube.com/TJ';
        instagramUrl = 'https://www.instagram.com/tjsmith/';
        facebookUrl = 'https://www.facebook.com/ivyleaguepunk/';
      }
      const opts = {
        height: '100%',
        width: '100%'
      };

      return (
        <div id="content" className="content">
          <div id={this.contestant}>
            <div className="row">
              <div className="contestant-header-container">
                <div className="contestant-header">
                  {header}
                </div>
              </div>
            </div>
            <div className="sand-background section">
              <div className="row contestant-info">
                <div className="col-sm-4 col-md-3" >
                  <img className="contestant-picture sand-frame" src={pic} alt=""/>
                  <div className="social-links" style={{marginTop: '10px', marginBottom: '10px'}}>
                    {twitchUrl && <a className="info-link" href={twitchUrl} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitch']}  size="2x"/></a>}
                    {twitterUrl && <a className="info-link" href={twitterUrl} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']}  size="2x"/></a>}
                    {youtubeUrl && <a className="info-link" href={youtubeUrl} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']}  size="2x"/></a>}
                    {instagramUrl && <a className="info-link" href={instagramUrl} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']}  size="2x"/></a>}
                    {facebookUrl && <a className="info-link" href={facebookUrl} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'facebook-f']} size="2x"/></a>}
                </div>
                </div>
                 <div className="col-sm-8 col-md-9">
                  <div className="regular-font background-semi-transparent sand-frame rounded">
                    <p>
                      <strong>Name:</strong> {name}
                    </p>
                    <p>
                      <strong>From:</strong> {location}
                    </p>
                    <p>
                      <strong>Favorite color(s)?</strong><br/>
                      {bioFavColor}
                    </p>
                    <p>
                      <strong>Favorite foods/drinks/snacks?</strong><br/>
                      {bioFavFood}
                    </p>
                    <p>
                      <strong>Favorite artists, bands, or genre of music to listen to?</strong><br/>
                      {bioFavMusic}
                    </p>
                    <p>
                      <strong>Any other interests in particular we should know about you?</strong><br/>
                      {bioInterests}
                    </p>
                    <p>
                      <strong>Short bio about yourself:</strong><br/>
                      {bioAbout}
                    </p>
                  </div>
                </div>
              </div>
              <br/>
              <div className="row contestant-video-section">
                <div className="col-md-4">
                  <YouTube
                    videoId={top32}
                    opts={opts}
                    containerClassName="contestant-video-container"
                    className="sand-frame"
                  />
                </div>
                <div className="col-md-4">
                  <YouTube
                    videoId={top16}
                    opts={opts}
                    containerClassName="contestant-video-container"
                    className="sand-frame"
                  />
                </div>
                <div className="col-md-4">
                  <YouTube
                    videoId={top8}
                    opts={opts}
                    containerClassName="contestant-video-container"
                    className="sand-frame"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

export default Contestants;



            