
import React, { Component } from 'react';
import NZXTLogo from './images/NZXTLogo.png';
import StreamlabsLogo from './images/StreamlabsLogo.png';
import LiveULogo from './images/LiveULogo.png';

class Staff extends Component {

    render() {
      return (
        <div id="content" className="content">
          <div className="sponsors-container white-container">
            <div className="sponsor-text">A huge thank you to our sponsors! Without their support, Streamer Camp wouldn't be possible!</div>
            <hr/>
            <div className="sponsor">
              <a href="https://www.nzxt.com/" target="_blank" rel="noopener noreferrer">
                <div className="row">
                  <div className="col-sm-4 col-xs-6 col-md-2">
                    <div className="sponsor-image" style={{paddingTop: '29.8%', paddingBottom: '29.8%'}}>
                      <img src={NZXTLogo} alt="NZXT"/>
                    </div>
                  </div>
                  <div className="col-sm-8 col-xs-6 col-md-10">
                    <h4 className="sponsor-name">NZXT</h4>
                  </div>
                </div>
              </a>
            </div>
            <div className="sponsor">
              <a href="https://streamlabs.com/" target="_blank" rel="noopener noreferrer">
                <div className="row">
                  <div className="col-sm-4 col-xs-6 col-md-2">
                    <div className="sponsor-image" style={{paddingTop: '6.29%', paddingBottom: '6.29%'}}>
                      <img src={StreamlabsLogo} alt="Streamlabs"/>
                    </div>
                  </div>
                  <div className="col-sm-8 col-xs-6 col-md-10">
                    <h4 className="sponsor-name">Streamlabs</h4>
                  </div>
                </div>
              </a>
            </div>
            <div className="sponsor">
              <a href="https://www.liveu.tv/" target="_blank" rel="noopener noreferrer">
                <div className="row">
                  <div className="col-sm-4 col-xs-6 col-md-2">
                    <div className="sponsor-image" style={{paddingTop: '28.66%', paddingBottom: '28.66%'}}>
                      <img src={LiveULogo} alt="LiveU"/>
                    </div>
                  </div>
                  <div className="col-sm-8 col-xs-6 col-md-10">
                    <h4 className="sponsor-name">LiveU</h4>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      );
    }
}

export default Staff;



            