
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Airzach from './images/airzach.png';
import Exlacraft from './images/exlacraft.png';
import Fasffy from './images/fasffy.png';
import HelloMeganLee from './images/hellomeganlee.png';
import KristoferYee from './images/kristoferyee.png';
import MitsukiBb from './images/mitsukibb.png';
import Negaoryx from './images/negaoryx.png';
import TJSmith from './images/tjsmith.png';
import SleightlyFusical from './images/SleightlyFusical.png'
import BoxyRae from './images/BoxyRae.png'

class Contestants extends Component {

    render() {
      return (
        <div id="content" className="content">
          <div className="contestants-main-row">
            <div className="row">
              <div className="col-md-6">
                <div className="contestants-container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="contestants-team">
                        <span className="host-name contestants-team-name">Team SleightlyFusical</span>
                        <img className="contestants-team-logo" src={SleightlyFusical} alt="SleightlyFusical"/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-6 col-md-6 contestants-main"><Link to="/contestants/airzach"><img src={Airzach} alt="airzach"/></Link></div>
                    <div className="col-xs-6 col-md-6 contestants-main"><Link to="/contestants/fasffy"><img src={Fasffy} alt="Fasffy"/></Link></div>
                  </div>
                  <div className="row">
                    <div className="col-xs-6 col-md-6 contestants-main"><Link to="/contestants/hellomeganlee"><img src={HelloMeganLee} alt="HelloMeganLee"/></Link></div>
                    <div className="col-xs-6 col-md-6 contestants-main"><Link to="/contestants/tjsmith"><img src={TJSmith} alt=""/></Link></div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contestants-container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="contestants-team">
                      <span className="host-name contestants-team-name">Team BoxyRae</span>
                        <img className="contestants-team-logo" src={BoxyRae} alt="BoxyRae"/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-6 col-md-6 contestants-main"><Link to="/contestants/exla"><img src={Exlacraft} alt="Exla"/></Link></div>
                    <div className="col-xs-6 col-md-6 contestants-main"><Link to="/contestants/kristoferyee"><img src={KristoferYee} alt=""/></Link></div>
                  </div>
                  <div className="row">
                    <div className="col-xs-6 col-md-6 contestants-main"><Link to="/contestants/mitsukibb"><img src={MitsukiBb} alt=""/></Link></div>
                    <div className="col-xs-6 col-md-6 contestants-main"><Link to="/contestants/negaoryx"><img src={Negaoryx} alt=""/></Link></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

export default Contestants;



            