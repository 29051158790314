import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Moment from 'react-moment';
import Select from 'react-select';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const options = [
  { value: 'Announcement', label: 'Announcement' },
  { value: 'Live Blog', label: 'Live Blog' },
  { value: 'Day 1', label: 'Day 1' },
  { value: 'Day 2', label: 'Day 2' },
  { value: 'Day 3', label: 'Day 3' },
  { value: 'Day 4', label: 'Day 4' },
];

class AdminBlogs extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        blogs : [],
        blogNames: [],
        blogOptions: [],
        blog: null,
        selectedBlog: null,
        blogName: null,
        isShow: true,
      };
    }
    success = () => {
      toast.success("Success!", {
        position: toast.POSITION.TOP_CENTER
      })
    }
    error = () => {
      toast.error("An error occured!", {
        position: toast.POSITION.TOP_CENTER
      })
    }
    handleSelectChange = (selectedBlog) => {
      this.setState({ selectedBlog: selectedBlog });
      this.getBlog(selectedBlog.value);
    }
    saveBlog = async () => {
      try {
        await API.post('streamercampapi', '/blogs', {
          headers: {
            'Authorization': 'Bearer ' + this.props.auth.getIdToken()
          },
          body: {
            BlogName: this.state.blog.BlogName,
            BlogTime: this.state.blog.BlogTime,
            BlogContent: this.state.editor.getData(),
          }
        });
        this.success();
      } catch(err) {
        this.error();
      }
    }
    getBlog = async (blogName) => {
      try {
        const response = await API.get('streamercampapi', '/blogs/' + blogName, {
          headers: {
            'Authorization': 'Bearer ' + this.props.auth.getIdToken()
          }});
        this.setState({ blogs: response });
        if (response.length > 0) {
          if (this.state.editor) {
            this.state.editor.setData(response[response.length - 1].BlogContent ? response[response.length - 1].BlogContent : "");
          }
          this.setState({ blogName: response[response.length - 1].BlogName });
          this.setState({ blog: response[response.length - 1] });
        } else {
          if (this.state.editor) this.state.editor.setData("");
          this.setState({ blogName: "" });
          this.setState({ blog: null });
        }
      } catch(err) {
        this.error();
      }
    }
    deleteLiveBlog  = async (blogTime) => {
      try {
        await API.del('streamercampapi', '/blogs/object/Live Blog/' + blogTime, {
          headers: {
            'Authorization': 'Bearer ' + this.props.auth.getIdToken()
          },
          body: {
            BlogName: "Live Blog",
            BlogTime: blogTime,
          }
        });
        this.getBlog("Live Blog");
        this.success();
      } catch(err) {
        this.error();
      }
    }
    addLiveBlog  = async () => {
      try {
        await API.post('streamercampapi', '/blogs', {
          headers: {
            'Authorization': 'Bearer ' + this.props.auth.getIdToken()
          },
          body: {
            BlogName: "Live Blog",
            BlogContent: this.state.editor.getData(),
          }
        });
        this.getBlog("Live Blog");
        this.success();
      } catch(err) {
        this.error();
      }
    }
  render() {
      if (!this.props.auth.getIdToken()) {
        return null;
      }
      var selectedBlog = this.state.selectedBlog;
      var blogName = this.state.blogName === null ? "" : this.state.blogName;
      var blogs = this.state.blogs.map((blog, key) =>
      <tr key={key}>
        <td><Moment local format="MMM DD, YYYY - hh:mm:ss a zz" date={blog.BlogTime}/></td>
        <td dangerouslySetInnerHTML={{ __html: blog.BlogContent }}></td>
        <td><Button
              variant="primary" className="btn btn-danger"
              onClick={() => this.deleteLiveBlog(blog.BlogTime)}>X</Button></td>
      </tr>
      );
      return (

        <div>
          <Select
            value={selectedBlog}
            onChange={this.handleSelectChange}
            options={options}
            className="select2-container"
          />
          <br/>
          <h2>{ blogName }</h2>
          
            <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                          plugins: [ 'Essentials', 'Paragraph', 'Bold', 'Italic', 'Heading', 'Link', 'BlockQuote', 'List', 'Table', 'TableToolbar' ],
                          toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable', '|', 'undo', 'redo' ],
                      } }
                      onInit={ editor => {
                          // You can store the "editor" and use when it is needed.
                          this.setState({editor: editor});
                      } }
                      onChange={ ( event, editor ) => {
                      } }
                      onBlur={ editor => {
                      } }
                      onFocus={ editor => {
                      } }
                  />
            <br/>
        { this.state.blogName && this.state.blogName !== 'Live Blog' &&
            <Button
              variant="primary" className="btn btn-primary"
              onClick={() => this.saveBlog()}
            >
              Save Blog
            </Button>
        }
        {this.state.selectedBlog && this.state.selectedBlog.value === "Live Blog" && 
            <Button
              variant="primary" className="btn btn-primary"
              onClick={() => this.addLiveBlog()}
            >
              Add Live Blog Entry
            </Button>
        }
        <br/>
        <br/>
        
        {this.state.selectedBlog && this.state.selectedBlog.value === "Live Blog" && 
          <table className="table admin-table">
          <thead>
            <tr>
              <th scope="col">LiveBlogEntryTime</th>
              <th scope="col">LiveBlogEntry</th>
              <th scope="col">Delete?</th>
            </tr>
          </thead>
          <tbody>
            { blogs }
          </tbody>
        </table>
        }
        <ToastContainer/>
        </div>
      );
  }
}

export default AdminBlogs;



            