import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Button } from 'react-bootstrap';
import MenuItemModal from './MenuItemModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AdminMenu extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        pages: [],
        menuItems : [],
        isShow: true,
      };
    }
    success = () => {
      toast.success("Success!", {
        position: toast.POSITION.TOP_CENTER
      })
    }
    error = () => {
      toast.error("An error occured!", {
        position: toast.POSITION.TOP_CENTER
      })
    }
    componentDidMount(){
        this.getPages();
        this.listAll();
    }
    getPages = async() => {
      try {
        const response = await API.get('streamercampapi', '/options/pages');
        this.setState({pages: response[0].OptionsValue});
      } catch(err) {
        this.error();
      }
    }
    savePages = async() => {
      try {
        let tempPages = this.state.pages.sort();
        await API.post('streamercampapi', '/options', {
          headers: {
            'Authorization': 'Bearer ' + this.props.auth.getIdToken()
          },
          body: {
            OptionsKey: 'pages',
            OptionsValue: tempPages
          }});
        this.success();
      } catch(err) {
        this.error();
      }
    }
    listAll = async (showToast) => {
      try {
        const response = await API.get('streamercampapi', '/menuitems', {
          headers: {
            'Authorization': 'Bearer ' + this.props.auth.getIdToken()
          }});
        this.setState({menuItems: response});
        if (showToast) {
          this.success();
        }
      } catch(err) {
        this.error();
      }
    }
    deleteMenuItem = async (menuItemKeyWord) => {
      try {
        await API.del('streamercampapi', '/menuitems/object/' + menuItemKeyWord, {
          headers: {
            'Authorization': 'Bearer ' + this.props.auth.getIdToken()
          }});
        this.listAll();
        this.success();
      } catch(err) {
        this.error();
      }
    }
    handleChecked(menuPage, e) {
      if (e.target.checked) {
        this.setState(prevState => ({
          pages: [...prevState.pages, menuPage]
        }));
      } else {
        let index = this.state.pages.indexOf(menuPage);
        this.setState({
          pages: this.state.pages.filter((_, i) => i !== index)
        });
        
      }
    }
  render() {
      if (!this.props.auth.getIdToken()) {
        return null;
      }
      let modalClose = () => this.setState({ modalShow: false });

      //Sort by team -> price -> name
      var sortedMenuItems = this.state.menuItems;
        sortedMenuItems.sort((a, b) => 
          (a.MenuItemTeam !== b.MenuItemTeam) ? a.MenuItemTeam.localeCompare(b.MenuItemTeam) :
          ((a.MenuItemPage !== b.MenuItemPage) ? parseFloat(a.MenuItemPage) - parseFloat(b.MenuItemPage) :
          ((a.MenuItemPrice !== b.MenuItemPrice) ? parseFloat(a.MenuItemPrice) - parseFloat(b.MenuItemPrice) : a.MenuItemDescription.localeCompare(b.MenuItemDescription)))
        );

      var menuItems = sortedMenuItems.map((menuItem, key) =>
      <tr key={key}>
        <th scope="row">{menuItem.MenuItemKeyWord}</th>
        <td>{menuItem.MenuItemDescription}</td>
        <td>{menuItem.MenuItemPrice}</td>
        <td>{menuItem.MenuItemTeam}</td>
        <td>{menuItem.MenuItemPage}</td>
        <td>
          <Button
          variant="primary" className="btn btn-danger"
          onClick={() => this.deleteMenuItem(menuItem.MenuItemKeyWord)}>
            X
        </Button>
        </td>
      </tr>
      );
      return (
        <div>
          <h2>Menu Items</h2>
          <div className="background-semi-transparent"><strong>Enable Pages:</strong>
          <form style={{paddingTop: '5px', paddingBottom: '5px'}}>
            <label class="checkbox-inline">
              <input type="checkbox" value="1" checked={this.state.pages.includes(1)} style={{width:'unset'}} onChange={(e) => this.handleChecked(1, e)}/>Page 1
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" value="2" checked={this.state.pages.includes(2)} style={{width:'unset'}} onChange={(e) => this.handleChecked(2, e)}/>Page 2
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" value="3" checked={this.state.pages.includes(3)} style={{width:'unset'}} onChange={(e) => this.handleChecked(3, e)}/>Page 3
            </label>
            <label class="checkbox-inline">
              <input type="checkbox" value="4" checked={this.state.pages.includes(4)} style={{width:'unset'}} onChange={(e) => this.handleChecked(4, e)}/>Page 4
            </label>
             <br/>
            <Button
              variant="primary" className="btn btn-primary" style={{marginTop: '10px'}}
              onClick={() => this.savePages()}
            >
              Save Pages
            </Button>
          </form>
          </div>
          <br/>
          <br/>
          <div className="background-semi-transparent">
            <Button
              variant="primary" className="btn btn-primary"
              onClick={() => this.setState({ modalShow: true })}
            >
              Add Menu Item
            </Button>
            <table className="table admin-table">
            <thead>
              <tr>
                <th scope="col">Key Word</th>
                <th scope="col">Description</th>
                <th scope="col">Price</th>
                <th scope="col">Team</th>
                <th scope="col">Page</th>
                <th scope="col">Delete?</th>
              </tr>
            </thead>
            <tbody>
              { menuItems }
            </tbody>
          </table>
        </div>
        <MenuItemModal 
          show={this.state.modalShow}
          onHide={modalClose}
          idtoken={this.props.auth.getIdToken()} 
          listAll={this.listAll}
        />
        <ToastContainer/>
        </div>
      );
  }
}

export default AdminMenu;



            