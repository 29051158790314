import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Moment from 'react-moment';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AdminOrders extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        orders: [],
        orderTeam: 'All',
      };
    }
    newOrder = () => {
      toast.success("There are new order(s)!", {
        position: toast.POSITION.TOP_CENTER
      })
    }
    success = () => {
      toast.success("Success!", {
        position: toast.POSITION.TOP_CENTER
      })
    }
    error = () => {
      toast.error("An error occured!", {
        position: toast.POSITION.TOP_CENTER
      })
    }
    componentDidMount(){
      this.getAllOrders(false);
      this.interval = setInterval(() => this.getAllOrders(true), 10000);
    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }
    getAllOrders = async (checkNewOrders) => {
      this.getOrders(checkNewOrders);
    }
    getOrders = async (checkNewOrders) => {
      try {
        let orderTeam = this.state.orderTeam;
        if (orderTeam === 'All') {
          orderTeam = '';
        }
        const response = await API.get('streamercampapi', '/orders/' + orderTeam + '', {
          headers: {
            'Authorization': 'Bearer ' + this.props.auth.getIdToken()
          }});
        if (checkNewOrders && response.length > this.state.orders.length) {
          this.newOrder();
        }
        this.setState({orders: response});
      } catch(err) {
        this.error();
      }
    }
    deleteOrder = async (orderTeam, orderTime) => {
      try {
        await API.del('streamercampapi', '/orders/object/' + orderTeam + '/' + orderTime, {
          headers: {
            'Authorization': 'Bearer ' + this.props.auth.getIdToken()
          }});
          this.getOrders();
          this.success();
      } catch(err) {
        this.error();
      }
    }
    completeOrder = async (order) => {
      try {
        await API.put('streamercampapi', '/orders', {
          headers: {
            'Authorization': 'Bearer ' + this.props.auth.getIdToken()
          },
          body: {
            MenuItemTeam: order.MenuItemTeam,
            OrderTime: order.OrderTime,
            OrderAmount: order.OrderAmount,
            OrderCurrency: order.OrderCurrency,
            OrderFrom: order.OrderFrom,
            OrderOriginalMessage: order.OrderOriginalMessage,
            OrderSummary: order.OrderSummary,
            OrderCompleted: true,
          }
        });
        this.getOrders();
        this.success();
      } catch(err) {
        this.error();
      }
    }
    handleTabClick = async (orderTeam) => {
      this.setState({orderTeam: orderTeam}, () => {
        this.getOrders();
      });
    }
    getOrderHtml(order, key) {
      return (<div key={key} className="order-entry">
        <div style={{textAlign:'right'}}>
          <Button
                variant="primary" className="btn btn-danger"
                onClick={() => this.deleteOrder(order.MenuItemTeam, order.OrderTime)}>Delete Order</Button>
        </div>
        <form class="form-horizontal">
        <div class="form-group">
          <div className="row">
            <label class="control-label col-md-2">User:</label>
            <div class="col-md-4">
              <p class="form-control-static">{order.OrderFrom}</p>
            </div>
            <label class="control-label col-md-2">Amount:</label>
            <div class="col-md-4">
              <p class="form-control-static">${order.OrderCurrency === 'Bits' ? parseFloat(order.OrderAmount)/100 + ' (' + order.OrderAmount + ' Bits)'  : order.OrderAmount}</p>
            </div>
          </div>
          <div className="row">
            <label class="control-label col-md-2">Date:</label>
            <div class="col-md-4">
              <p class="form-control-static"><Moment local format="MMM DD, YYYY - hh:mm:ss a zz" date={order.OrderTime}/></p>
            </div>
            <label class="control-label col-md-2">Payment Method:</label>
            <div class="col-md-4">
              <p class="form-control-static">{order.OrderCurrency === 'Bits' ? 'Bits' : 'Streamlabs (' + order.OrderCurrency + ')'}</p>
            </div>
          </div>
          <div className="row">
            <label class="control-label col-md-2">For Team:</label>
            <div class="col-md-4">
              <p class="form-control-static">{order.MenuItemTeam}</p>
            </div>
            <label class="control-label col-md-2">Status</label>
            <div class="col-md-4">
              <p class="form-control-static">{order.OrderCompleted === true ? <span style={{color: '#5cb85c'}}><strong>Completed</strong></span> : <span style={{color: '#ec971f'}}><strong>Pending</strong></span>}</p>
            </div>
          </div>
          <div className="row">
            <label class="control-label col-md-2">Note From User:</label>
            <div class="col-md-10">
              <p class="form-control-static"><i>{order.OrderOriginalMessage}</i></p>
            </div>
          </div>

          <div className="row">
            <div className="order-summary col-md-8 center-block">
              <table className="order-table" align="center">
                <tbody>
                  <tr>
                    <td>
                    <table className="order-table-inner" cellspacing="0" cellpadding="8">
                      <tbody>
                      <tr>
                        <td style={{color:'#000',borderBottom:'1px solid #fff',fontWeight:'bold',textAlign:'left', padding: '10px'}}>Item</td>
                        <td style={{color:'#000',borderBottom:'1px solid #fff',fontWeight:'bold',width:'40px',textAlign:'right',borderLeft: 'unset', padding: '10px'}}>Price</td>
                      </tr>
                      { order.OrderSummary && order.OrderSummary.map((menuItem, key) =>
                      <tr>
                          <td style={{color:'#555',fontWeight:'bold',textAlign:'left',borderRight:'1px solid #fff',borderBottom:'1px solid #fff', padding: '10px'}}>{menuItem.menuItemDescription}</td>
                          <td style={{textAlign:'right',color:'#555',borderBottom:'1px solid #fff', padding: '10px', borderLeft: '1px solid rgb(255, 255, 255)'}}><span>${menuItem.menuItemPrice}</span> </td>
                      </tr>
                      )}
                      </tbody>
                    </table>
                    <div style={{textAlign:'right',padding:'10px 8px 10px 0px',color:'#000',fontWeight:'bold',backgroundColor:'#f2f2f2',borderTop:'1px solid #fff'}}>Total Required: <span>
                        ${ order.OrderSummary ? order.OrderSummary.reduce((total, menuItem) => parseFloat(total) + parseFloat(menuItem.menuItemPrice), 0) : '0'}
                      </span>
                      </div>
                      { order.OrderCurrency === 'Bits' &&
                        ((order.OrderSummary && parseFloat(order.OrderAmount)/100 >= order.OrderSummary.reduce((total, menuItem) => parseFloat(total) + parseFloat(menuItem.menuItemPrice), 0)) ||
                        (!order.OrderSummary) ? 
                        <div style={{textAlign:'right',padding:'10px 8px 10px 0px',color:'#000',fontWeight:'bold',backgroundColor:'#f2f2f2',borderTop:'1px solid #fff'}}>Total Amount: <span>${parseFloat(order.OrderAmount)/100}</span> </div> :
                        <div style={{textAlign:'right',padding:'10px 8px 10px 0px',color:'#000',fontWeight:'bold',backgroundColor:'#fcbcbc',borderTop:'1px solid #fff'}}>Total Amount: <span>${parseFloat(order.OrderAmount)/100}</span> </div>)
                      }
                      { order.OrderCurrency !== 'Bits' &&
                        ((order.OrderSummary && parseFloat(order.OrderAmount) >= order.OrderSummary.reduce((total, menuItem) => parseFloat(total) + parseFloat(menuItem.menuItemPrice), 0)) ||
                        (!order.OrderSummary)  ?
                        <div style={{textAlign:'right',padding:'10px 8px 10px 0px',color:'#000',fontWeight:'bold',backgroundColor:'#f2f2f2',borderTop:'1px solid #fff'}}>Total Amount: <span>${order.OrderAmount}</span> </div> : 
                        <div style={{textAlign:'right',padding:'10px 8px 10px 0px',color:'#000',fontWeight:'bold',backgroundColor:'#fcbcbc',borderTop:'1px solid #fff'}}>Total Amount: <span>${order.OrderAmount}</span> </div>)
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="order-complete">
                { order.OrderCompleted !== true ?
                    <Button
                        variant="primary" className="btn btn-primary btn-lg"
                        onClick={() => this.completeOrder(order)}>Complete Order</Button> :
                    <div class="alert alert-success" role="alert">
                      <span style={{fontSize: '2.5rem', fontWeight: '600'}}>Order Complete!</span>
                    </div>
                }
              </div>
            </div>
          </div>


        </div>
        </form>
      </div>)
    }
  render() {
      if (!this.props.auth.getIdToken()) {
        return null;
      }
      // var orders = this.state.orders.map((order, key) =>
      //   order.OrderCompleted !== true && this.getOrderHtml(order, key)
      // );
        
      // var completedOrders = this.state.orders.map((order, key) =>
      //   order.OrderCompleted === true && this.getOrderHtml(order, key)
      // );
      
      var pendingOrders = [];
      var completedOrders = [];
      for (let i = 0; i < this.state.orders.length; i++) {
        if (this.state.orders[i].OrderCompleted !== true) {
          pendingOrders.push(this.getOrderHtml(this.state.orders[i], i));
        } else {
          completedOrders.push(this.getOrderHtml(this.state.orders[i], i));
        }
      }
      return (
        <div>
          <Tabs defaultActiveKey="All" id="uncontrolled-tab-example" onSelect={this.handleTabClick} >
              <Tab eventKey="All" title="All">
              </Tab>
              <Tab eventKey="SleightlyFusical" title="SleightlyFusical">
              </Tab>
              <Tab eventKey="BoxyRae" title="BoxyRae">
              </Tab>
            </Tabs>
          <h2>Pending Orders</h2>
          <div className="order">
            <div className="row">
              <div className="col-md-12">
                { pendingOrders.length === 0 ? <h3>There are no pending orders!</h3> : pendingOrders }
              </div>
            </div>
          </div>
          <br/>
          <h2>Completed Orders</h2>
          <div className="order">
            <div className="row">
              <div className="col-md-12">
                { completedOrders.length === 0 ? <h3>There are no completed orders!</h3> : completedOrders }
              </div>
            </div>
          </div>
          <ToastContainer/>
        </div>
      );
  }
}

export default AdminOrders;



            