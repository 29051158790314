
import React, { Component } from 'react';
import Brodin from './images/brodin-profile.jpg';
import Cheery from './images/cheery-profile.jpg';
import Oliver from './images/oliver-profile.jpg';
import Annie from './images/annie-profile.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import cobes from './images/cobes.jpg';
import emptym from './images/emptym.jpg';
import heroimalan from './images/heroimalan.jpg';
import jikku from './images/jikku.jpg';
import misterarther from './images/misterarther.jpg';
import ramosdotmp4 from './images/ramosdotmp4.jpg';

library.add(fab)

class Staff extends Component {

  getStaffHtml(header,
              pic,
              name,
              location,
              role,
              bioFavColor,
              bioFavFood,
              bioFavMusic,
              bioInterests,
              bioAbout,
              twitchUrl,
              twitterUrl,
              youtubeUrl,
              instagramUrl,
              facebookUrl,
              websiteUrl) {
    return (
      <div>
      <div className="row">
        <div className="contestant-header-container">
          <div className="contestant-header">
            {header}
          </div>
        </div>
      </div>
    <div className="sand-background section">
    <div className="row contestant-info">
      <div className="col-sm-4 col-md-3" >
        <img className="contestant-picture sand-frame" src={pic} alt=""/>
        <div className="social-links" style={{marginTop: '10px', marginBottom: '10px'}}>
            {twitchUrl && <a className="info-link" href={twitchUrl} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitch']}  size="2x"/></a>}
            {twitterUrl && <a className="info-link" href={twitterUrl} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']}  size="2x"/></a>}
            {youtubeUrl && <a className="info-link" href={youtubeUrl} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']}  size="2x"/></a>}
            {instagramUrl && <a className="info-link" href={instagramUrl} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']}  size="2x"/></a>}
            {facebookUrl && <a className="info-link" href={facebookUrl} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'facebook-f']} size="2x"/></a>}
            {websiteUrl && <a className="info-link" href={websiteUrl} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGlobe} size="2x"/></a>}
        </div>
      </div>
      <div className="col-sm-8 col-md-9">
        <div className="regular-font background-semi-transparent sand-frame rounded">
          <p>
            <strong>Name:</strong> {name}
          </p>
          <p>
            <strong>From:</strong> {location}
          </p>
          <p>
            <strong>Role:</strong> {role}
          </p>
          <p>
            <strong>Favorite color(s)?</strong><br/>
            {bioFavColor}
          </p>
          <p>
            <strong>Favorite foods/drinks/snacks?</strong><br/>
            {bioFavFood}
          </p>
          <p>
            <strong>Favorite artists, bands, or genre of music to listen to?</strong><br/>
            {bioFavMusic}
          </p>
          <p>
            <strong>Any other interests in particular we should know about you?</strong><br/>
            {bioInterests}
          </p>
          <p>
            <strong>Short bio about yourself:</strong><br/>
            {bioAbout}
          </p>
        </div>
      </div>
    </div>
  </div>
  </div>)
  }

    render() {
      return (
        <div id="content" className="content">
          {this.getStaffHtml('Brodin',
          Brodin,
          'Brodin',
          'Los Angeles, California',
          'Videographer',
          'Black/Blue/White',
          'Melon Soda/ Hot Cheetos/Unhealthy Things',
          'Everything: Kpop, Porter Robinson, Movie/Video Game Soundtracks, etc.',
          'Exploring new countries. traveling, watching movies, anime, video games, making movies, making music, drawing, graphic design, and most creative things',
          'I\'m a filmmaker living in LA. I direct/produce commercials, short films, and various esports content. I have traveled much of the world and always seem to find myself in interesting situations.',
          null,
          'https://twitter.com/brodinplett',
          null,
          null,
          null,
          'https://brodinplett.com/')}
          <br/>
          <br/>
          {this.getStaffHtml('Cheery',
          Cheery,
          'Cheery',
          'Vancouver, British Columbia',
          'Social Media Manager',
          'Purple',
          'Oreos / Ginger Ale',
          'EDM!! GETS ME BUMPIN.',
          'Use to stream but never did it full time! And worked a bit in esports. :D ',
          'Hi I\'m Cheery! I\'ve been live on Twitch since 2013 and casually stream here and there.  I finished my BA in English at Simon Fraser University. I\'ve also worked at Akshon Esports, an esports media company based in Vancouver as a Social Media Manager. Now, I work full-time alongside your favourite content creators on helping them develop their brand, content management and marketing, in hopes of creating something of my own. Aside from my work, I love playing board games, going to music festivals and seeing the world.',
          'https://www.twitch.tv/cheery',
          'https://twitter.com/cheeryio',
          'https://www.youtube.com/cheery',
          'https://www.instagram.com/cheeryio/',
          'https://www.facebook.com/cheeryio',
           null)}
          <br/>
          <br/>
          {this.getStaffHtml('Annie',
          Annie,
          'Annie',
          'United States',
          'Assistant',
          'Purple, Blue',
          'Chips and bacon :D',
          'Anything!',
          'I don\'t get stressed easily, and am super detail oriented! I\'m pretty easy going, my favorite past time is making people laugh.',
          <span>I'm Annie, and I'm a student trying her best, and a cosplayer! My favorite pastime is playing games with all my friends :D! I'm a huge fan of art and I love drawing and making cosplays (my instagram is <a href="https://www.instagram.com/yeriyericos/" target="_blank" rel="noopener noreferrer">/yeriyericos</a>) !!</span>,
          'https://www.twitch.tv/hai_yun',
          'https://twitter.com/haiyuunn',
          null,
          'https://www.instagram.com/yeriyericos/',
          'https://www.facebook.com/yeriyericos/',
          null)}
          <br/>
          <br/>
          {this.getStaffHtml('Olly',
          Oliver,
          'Oliver',
          'Los Angeles, California',
          'Photographer',
          'Black',
          'Tacos / Takis / chicken pesto pasta ',
          'Anything but country. JVNA is pretty cool :) ',
          'Photography',
          'I go by Olly and I dropped out of school to pursue photography. ',
          'https://www.twitch.tv/oshou',
          null,
          null,
          'https://www.instagram.com/o.shou/',
          null,
          'https://www.oshou.co/')}
          <br/>
          <br/>
          <br/>
          <div className="home-subsection-banner" style={{textAlign: 'center', marginBottom: '0px', borderBottom: '0px'}}>
            Special Thanks
          </div>
          <div class="sand-background section">
            <div className="row">
              <div className="col-md-2 col-sm-4 col-xs-6">
                <div className="special-thanks-section">
                  <div className="sand-frame" style={{marginBottom: '10px'}}>
                    <img className="contestant-picture" src={cobes}/>
                  </div>
                  <div className="background-semi-transparent sand-frame rounded special-thanks-info">
                    <table className="table-stick-bottom">
                      <tbody>
                        <tr>
                          <td valign="top">
                            <div><strong>cobes</strong></div>
                            <div>IRL Backpack</div>
                          </td>
                        </tr>
                        <tr>
                          <td valign="bottom">
                            <div className="social-links" style={{marginTop: '10px'}}>
                              <a className="info-link" href="https://www.twitch.tv/cobes" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitch']}  size="2x"/></a>
                              <a className="info-link" href="https://twitter.com/cobes__" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']}  size="2x"/></a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-4 col-xs-6">
                <div className="special-thanks-section">
                  <div className="sand-frame" style={{marginBottom: '10px'}}>
                    <img className="contestant-picture" src={emptym}/>
                  </div>
                    <div className="background-semi-transparent sand-frame rounded special-thanks-info">
                    <table className="table-stick-bottom">
                      <tbody>
                        <tr>
                          <td valign="top">
                            <div><strong>EmptyM</strong></div>
                            <div>Website</div>
                          </td>
                        </tr>
                        <tr>
                          <td valign="bottom">
                            <div className="social-links" style={{marginTop: '10px'}}>
                              <a className="info-link" href="https://www.twitch.tv/EmptyM" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitch']}  size="2x"/></a>
                              <a className="info-link" href="https://twitter.com/Im_EmptyM" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']}  size="2x"/></a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-4 col-xs-6">
                <div className="special-thanks-section">
                  <div className="sand-frame" style={{marginBottom: '10px'}}>
                    <img className="contestant-picture" src={heroimalan}/>
                  </div>
                    <div className="background-semi-transparent sand-frame rounded special-thanks-info">
                    <table className="table-stick-bottom">
                      <tbody>
                        <tr>
                          <td valign="top">
                            <div><strong>heroimalan</strong></div>
                            <div>Streamer Camp Logo</div>
                          </td>
                        </tr>
                        <tr>
                          <td valign="bottom">
                            <div className="social-links" style={{marginTop: '10px'}}>
                              <a className="info-link" href="https://www.instagram.com/heroimalan" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']}  size="2x"/></a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-4 col-xs-6">
                <div className="special-thanks-section">
                  <div className="sand-frame" style={{marginBottom: '10px'}}>
                    <img className="contestant-picture" src={jikku}/>
                  </div>
                    <div className="background-semi-transparent sand-frame rounded special-thanks-info">
                    <table className="table-stick-bottom">
                      <tbody>
                        <tr>
                          <td valign="top">
                            <div><strong>Jikku</strong></div>
                            <div>Team Logos and Twitch Emotes</div>
                          </td>
                        </tr>
                        <tr>
                          <td valign="bottom">
                            <div className="social-links" style={{marginTop: '10px'}}>
                              <a className="info-link" href="https://www.twitch.tv/Jikku" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitch']}  size="2x"/></a>
                              <a className="info-link" href="https://twitter.com/datsean" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']}  size="2x"/></a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-4 col-xs-6">
                <div className="special-thanks-section">
                  <div className="sand-frame" style={{marginBottom: '10px'}}>
                    <img className="contestant-picture" src={misterarther}/>
                  </div>
                    <div className="background-semi-transparent sand-frame rounded special-thanks-info">
                    <table className="table-stick-bottom">
                      <tbody>
                        <tr>
                          <td valign="top">
                            <div><strong>MisterArther</strong></div>
                            <div>IRL Backpack</div>
                          </td>
                        </tr>
                        <tr>
                          <td valign="bottom">
                            <div className="social-links" style={{marginTop: '10px'}}>
                                <a className="info-link" href="https://www.twitch.tv/MisterArther" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitch']}  size="2x"/></a>
                                <a className="info-link" href="https://twitter.com/misterarther" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']}  size="2x"/></a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-4 col-xs-6">
                <div className="special-thanks-section">
                  <div className="sand-frame" style={{marginBottom: '10px'}}>
                    <img className="contestant-picture" src={ramosdotmp4}/>
                  </div>
                    <div className="background-semi-transparent sand-frame rounded special-thanks-info">
                    <table className="table-stick-bottom">
                      <tbody>
                        <tr>
                          <td valign="top">
                            <div><strong>RamosDotMp4</strong></div>
                            <div>Video Editor</div>
                          </td>
                        </tr>
                        <tr>
                          <td valign="bottom">
                            <div className="social-links" style={{marginTop: '10px'}}>
                                <a className="info-link" href="https://twitter.com/RamosDotMp4" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']}  size="2x"/></a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

export default Staff;



            