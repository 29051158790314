import React from 'react';
import { Route, Router } from 'react-router-dom';
import App from './App';
import Admin from './Admin/Admin';
import Callback from './Callback/Callback';
import Auth from './Auth/Auth';
import history from './history';
import Header from './Header.jsx';
import Contestants from './Contestants';
import Contestant from './Contestant';
import Staff from './Staff';
import Sponsors from './Sponsors';
import Menu from './Menu';
import Recaps from './Recaps';
import VODs from './VODs';
import Clips from './Clips';
import Pictures from './Pictures';
import Merch from './Merch';

const auth = new Auth();

const handleAuthentication = ({location}) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
}

export const makeMainRoutes = () => {
  return (
      <Router history={history}>
      <div className="content-wrapper">
          <Header/>
          <Route exact path="/" render={(props) => <App auth={auth} {...props} />} />
          <Route path="/menu" render={(props) => <Menu auth={auth} {...props} />} />
          <Route exact path="/contestants" render={(props) => <Contestants auth={auth} {...props} />} />
          <Route path="/contestants/:contestant" render={(props) => <Contestant auth={auth} {...props} />} />
          <Route path="/staff" render={(props) => <Staff auth={auth} {...props} />} />
          <Route path="/sponsors" render={(props) => <Sponsors auth={auth} {...props} />} />
          <Route path="/recaps" render={(props) => <Recaps auth={auth} {...props} />} />
          <Route path="/vods" render={(props) => <VODs auth={auth} {...props} />} />
          <Route path="/clips" render={(props) => <Clips auth={auth} {...props} />} />
          <Route path="/pictures" render={(props) => <Pictures auth={auth} {...props} />} />
          <Route path="/merch" render={(props) => <Merch auth={auth} {...props} />} />
          <Route path="/admin" render={(props) => <Admin auth={auth} {...props} />} />
          <Route path="/callback" render={(props) => {
            handleAuthentication(props);
            return <Callback {...props} /> 
          }}/>
        </div>
      </Router>
  );
}
