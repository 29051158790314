import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Moment from 'react-moment';
import { Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AdminMedia extends Component {
      constructor(props) {
        super(props);

        this.state = {
          media : [],
        };
      }
    success = () => {
      toast.success("Success!", {
        position: toast.POSITION.TOP_CENTER
      })
    }
    error = () => {
      toast.error("An error occured!", {
        position: toast.POSITION.TOP_CENTER
      })
    }
    addMedia = async (values) => {
      try {
        await API.post('streamercampapi', '/media', {
          headers: {
            'Authorization': 'Bearer ' + this.props.auth.getIdToken()
          },
          body: {
            MediaType: values.mediaType,
            MediaBlogName: values.mediaBlog,
            MediaLink: values.mediaLink,
            MediaThumbnail: values.mediaThumbnail,
            MediaTitle: values.mediaTitle,
            MediaTime: new Date().getTime()
          }
        });
        this.loadAllMedia();
        this.success();
      } catch(err) {
        this.error();
      }
    }

    loadAllMedia  = async () => {
      try {
        const response = await API.get('streamercampapi', '/media', {
          headers: {
            'Authorization': 'Bearer ' + this.props.auth.getIdToken()
          }
        });
        this.setState({media: response});
      } catch(err) {
        this.error();
      }
    }

    deleteMedia = async (mediaType, mediaTime) => {
      try {
        await API.del('streamercampapi', '/media/object/' + mediaType + '/' + mediaTime, {
          headers: {
            'Authorization': 'Bearer ' + this.props.auth.getIdToken()
          },
          body: {
            MediaType: mediaType,
            MediaTime: mediaTime,
          }
        });
        this.loadAllMedia();
        this.success();
      } catch(err) {
        this.error();
      }
    }
  render() {
      if (!this.props.auth.getIdToken()) {
        return null;
      }
      var media = this.state.media.map((mediaItem, key) =>
      <tr key={key}>
        <th scope="row"><img style={{maxWidth:'200px'}} src={mediaItem.MediaThumbnail} alt=""/></th>
        <td>{mediaItem.MediaTitle}</td>
        <td>{mediaItem.MediaLink}</td>
        <td>{mediaItem.MediaType}</td>
        <td>{mediaItem.MediaBlogName}</td>
        <td><Moment local format="MMM DD, YYYY - hh:mm:ss a zz" date={mediaItem.MediaTime}/></td>
        <td><Button
              variant="primary" className="btn btn-danger"
              onClick={() => this.deleteMedia(mediaItem.MediaType, mediaItem.MediaTime)}>X</Button></td>
      </tr>
      );
      return (
        <div>
          <Formik
            initialValues={{ mediaBlog: "Day 1", mediaType: "Clip" , mediaLink: "" , mediaTitle: "" , mediaThumbnail: "" }}
            onSubmit={(values, { setSubmitting }) => {
              this.addMedia(values);
              setTimeout(() => {
                setSubmitting(false);
              }, 500);
            }}
            validationSchema={Yup.object().shape({
              mediaLink: Yup.string().url()
                .required('Required'),
              mediaTitle: Yup.string()
                .required('Required'),
              mediaThumbnail: Yup.string().url()
                .required('Required'),
            })}
          >
          
            {props => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;

              return (
                <form onSubmit={handleSubmit} className="form-horizontal background-semi-transparent">
                  <div className="form-group">
                    <label htmlFor="mediaBlog" style={{ display: 'block' }} className="col-sm-3 control-label"> 
                      Blog
                    </label>
                    <div className="col-sm-9">
                      <select
                          id="mediaBlog"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.mediaBlog && touched.mediaBlog ? 'text-input error form-control' : 'text-input form-control'
                          }>
                        <option value="Day 1">Day 1</option>
                        <option value="Day 2">Day 2</option>
                        <option value="Day 3">Day 3</option>
                        <option value="Day 4">Day 4</option>
                        <option value="Other">Other</option>
                      </select>
                        {errors.mediaBlog && touched.mediaBlog && (
                          <div className="input-feedback">{errors.mediaBlog}</div>
                        )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="mediaType" style={{ display: 'block' }} className="col-sm-3 control-label"> 
                      Media Type
                    </label>
                    <div className="col-sm-9">
                      <select
                          id="mediaType"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.mediaType && touched.mediaType ? 'text-input error form-control' : 'text-input form-control'
                          }>
                        <option value="Clip">Clip</option>
                        <option value="VOD">VOD</option>
                        <option value="Picture">Picture</option>
                      </select>
                        {errors.mediaType && touched.mediaType && (
                          <div className="input-feedback">{errors.mediaType}</div>
                        )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="mediaLink" style={{ display: 'block' }} className="col-sm-3 control-label"> 
                      Link
                    </label>
                    <div className="col-sm-9">
                      <input
                        id="mediaLink"
                        placeholder="Link to media (including http://)"
                        type="text"
                        value={values.mediaLink}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.mediaLink && touched.mediaLink ? 'text-input error' : 'text-input'
                        }
                      />
                      {errors.mediaLink && touched.mediaLink && (
                        <div className="input-feedback">{errors.mediaLink}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="mediaTitle" style={{ display: 'block' }} className="col-sm-3 control-label"> 
                      Title/Caption
                    </label>
                    <div className="col-sm-9">
                      <input
                        id="mediaTitle"
                        placeholder="Title/Caption of media"
                        type="text"
                        value={values.mediaTitle}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.mediaTitle && touched.mediaTitle ? 'text-input error' : 'text-input'
                        }
                      />
                      {errors.mediaTitle && touched.mediaTitle && (
                        <div className="input-feedback">{errors.mediaTitle}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="mediaThumbnail" style={{ display: 'block' }} className="col-sm-3 control-label"> 
                      Thumbnail Link
                    </label>
                    <div className="col-sm-9">
                      <input
                        id="mediaThumbnail"
                        placeholder="Link to thumbnail image (including http://)"
                        type="text"
                        value={values.mediaThumbnail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.mediaThumbnail && touched.mediaThumbnail ? 'text-input error' : 'text-input'
                        }
                      />
                      {errors.mediaThumbnail && touched.mediaThumbnail && (
                        <div className="input-feedback">{errors.mediaThumbnail}</div>
                      )}
                    </div>
                  </div>
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary btn btn-default">
                    Add Media
                  </button>

                </form>
              );
            }}
          </Formik>

          <br/>
          <div className="background-semi-transparent">
            <h2>Added Media</h2>
            <button  onClick={() => this.loadAllMedia()} className="btn btn-primary btn btn-default">
              Load Media
            </button>
            
            <table className="table admin-table">
            <thead>
              <tr>
                <th scope="col">Thumbnail</th>
                <th scope="col">Title</th>
                <th scope="col">Link</th>
                <th scope="col">Media Type</th>
                <th scope="col">Blog</th>
                <th scope="col">Media Time</th>
                <th scope="col">Delete?</th>
              </tr>
            </thead>
            <tbody>
              { media }
            </tbody>
          </table>
          </div>
          <ToastContainer/>
        </div>
      );
  }
}

export default AdminMedia;



            