import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Tab, Tabs } from 'react-bootstrap';
import StreamerCampBadge from './images/StreamerCampBadge3.png'
import moment from 'moment';
import Moment from 'react-moment';
import LiveBlog from './LiveBlog';


class Recaps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blogs: [],
      blog: {'BlogName': 'Live Blog', 'BlogContent': ''},
      blogContent: "<p></p>",
      clips: [],
      pictures: [],
      vods: [],
    };
  }
  componentDidUpdate() {
  }

  componentDidMount() {
    this.handleTabClick('Live Blog');
  }

  handleTabClick = async (blogName) => {
    this.setState({clips: []});
    this.setState({pictures: []});
    this.setState({vods: []});
    const response = await API.get('streamercampapi', '/blogs/' + blogName);
    this.setState({blogs: response});
    if (response.length > 0) {
      this.setState({blogContent: response[0].BlogContent});
      this.setState({blog: response[0]})
    }
    this.getClips(blogName);
    this.getPictures(blogName);
    this.getVODs(blogName);
  }

  getClips = async (blogName) => {
    const response = await API.get('streamercampapi', '/media/Clip?blog=' + blogName);
    this.setState({clips: response});
  }

  getPictures = async (blogName) => {
    const response = await API.get('streamercampapi', '/media/Picture?blog=' + blogName);
    this.setState({pictures: response});
  }

  getVODs = async (blogName) => {
    const response = await API.get('streamercampapi', '/media/VOD?blog=' + blogName);
    this.setState({vods: response});
  }

  render() {    
    var liveblog = [];
    var date=null;
    
    for(let i = 0; i < this.state.blogs.length; i++) {
      let orderDate = moment(this.state.blogs[i].BlogTime).format('MMMM Do, YYYY');
      if (date !== orderDate) {
        liveblog.push(
          <div key={i + 'chat-day'} className="chat-day"><strong>{orderDate}</strong></div>
        )
        date = orderDate;
      }
      liveblog.push(
        <div key={i + 'chat-message'} className="chat-message">
        <div>
          <Moment local format="h:mm zz" date={this.state.blogs[i].BlogTime}/>
          <img alt="Broadcaster" aria-label="Broadcaster badge" className="chat-badge" src="https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/4" srcSet="https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/1 1x, https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/2 2x, https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/3 4x"/>
          <img alt="Badge" aria-label="Verified badge" className="chat-badge" src={StreamerCampBadge} ></img>
          <img alt="Verified" aria-label="Verified badge" className="chat-badge" src="https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/4" srcSet="https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/1 1x, https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/2 2x, https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/3 4x"></img>
          <span className="chat-user">StreamerCampTV: </span><span dangerouslySetInnerHTML={{ __html : this.state.blogs[i].BlogContent.replace("<p>", "").replace("</p>", "")}}></span>
        </div>
      </div>
      )
    }

    var vods = this.state.vods.map((vod, key) =>
      <div key={key} className="col-md-3">
        <a href={vod.MediaLink} target="_blank" rel="noopener noreferrer" className="home-video-link">
          <div className="home-video-container">
            <img className="home-thumbnail sand-frame" src={vod.MediaThumbnail} alt=""/>
            <div className="home-thumbnail-description">
              <strong>{vod.MediaTitle}</strong>
            </div>
          </div>
        </a>
      </div>
    );

    var clips = this.state.clips.map((clip, key) =>
      <div key={key} className="col-md-3">
        <a href={clip.MediaLink} target="_blank" rel="noopener noreferrer" className="home-video-link">
          <div className="home-video-container">
            <img className="home-thumbnail sand-frame" src={clip.MediaThumbnail} alt=""/>
            <div className="home-thumbnail-description">
              <strong>{clip.MediaTitle}</strong>
            </div>
          </div>
        </a>
      </div>
    );

    var pictures = this.state.pictures.map((picture, key) =>
      <div key={key} className="col-md-3">
        <a href={picture.MediaLink} target="_blank" rel="noopener noreferrer" className="home-video-link">
          <div className="home-video-container">
            <img className="home-thumbnail sand-frame" src={picture.MediaThumbnail} alt=""/>
            <div className="home-thumbnail-description">
              <strong>{picture.MediaTitle}</strong>
            </div>
          </div>
        </a>
      </div>
    );

    return (
        <div id="content" className="content">
        <div className="section sand-background">
              <Tabs defaultActiveKey="Live Blog" onSelect={this.handleTabClick} id="recap-tabs">
              <Tab eventKey="Live Blog" title="Live Blog">
              </Tab>
              <Tab eventKey="Day 1" title="Day 1">
              </Tab>
              <Tab eventKey="Day 2" title="Day 2">
              </Tab>
              <Tab eventKey="Day 3" title="Day 3">
              </Tab>
              <Tab eventKey="Day 4" title="Day 4">
              </Tab>
            </Tabs>
            <div className="row">
                <div className="col-md-12">
                  <div className="home-subsection-banner">
                    {this.state.blog.BlogName}
                  </div>
                    { this.state.blog.BlogName !== 'Live Blog' ?
                    <div className="regular-font background-semi-transparent recap-blog" dangerouslySetInnerHTML={{__html: this.state.blogContent}}></div> : 
                    
                    // <div className="regular-font background-chat" style={{height: 'auto'}}>
                    //   <div className="chat-header">
                    //     <div className="chat-header-left">Live Blog</div>
                    //   </div>
                    //   <div className="chat-body">
                    //     <div data-a-target="chat-welcome-message" className="chat-message chat-message-moderation"><span>Welcome to streamercamp.tv!</span></div>
                    //     { liveblog }
                    //     <div style={{ float:"left", clear: "both" }}
                    //         ref={(el) => { this.messagesEnd = el; }}>
                    //     </div>
                    //   </div>
                    // </div>
                    
                    <LiveBlog homepage={false}/>
                    }
                </div>
            </div>
            { this.state.blog.BlogName !== 'Live Blog' && 
              <div className="row">
                  <div className="col-md-12">
                    <div className="home-subsection-banner">
                      Clips
                    </div>
                    <div className="row">
                      { clips }
                    </div>
                  </div>
              </div>
            }
            { this.state.blog.BlogName !== 'Live Blog' && 
              <div className="row">
                  <div className="col-md-12">
                    <div className="home-subsection-banner">
                      Pictures
                    </div>
                    <div className="row">
                      { pictures }
                    </div>
                  </div>
              </div>
            }
            { this.state.blog.BlogName !== 'Live Blog' && 
              <div className="row">
                  <div className="col-md-12">
                    <div className="home-subsection-banner">
                      VODs
                    </div>
                    <div className="row">
                      { vods }
                    </div>
                  </div>
              </div>
            }
          </div>
      </div>
    );
  }
}

export default Recaps;
