
import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Button } from 'react-bootstrap';
import Slider from "react-slick";
import './Menu.css';

class Menu extends Component {
  constructor (props) {
      super(props);
      this.state = {
        pages: [],
        cart: [],
        cartPrice: 0,
        menuItems : [],
      }
    }
    getPages = async() => {
      const response = await API.get('streamercampapi', '/options/pages');
      this.setState({pages: response[0].OptionsValue});
      console.log(response);
    }
    listAll = async () => {
      console.log('calling api');
      const response = await API.get('streamercampapi', '/menuitems');
      this.setState({menuItems: response});
    }
    componentDidMount() {
      this.getPages();
      this.interval = setInterval(() => this.getPages(), 120000);

      this.listAll();
    }
    componentWillUnmount() {
      clearInterval(this.interval);
    }

    checkOutClicked() {
      const url = 'https://streamlabs.com/fuslie';
      window.open(url, '_blank');
    }

    copyCartClicked = () => {
      this.textArea.select();
      document.execCommand('copy');
    }

    copyTotalClicked = () => {
      var textField = document.createElement('textarea')
      textField.innerText = this.state.cartPrice
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
    }

    handleChecked(menuItemDescription, menuItemPrice, e) {
      if (e.target.checked) {
        this.setState({cartPrice: this.state.cartPrice + parseFloat(menuItemPrice)});
        this.setState(prevState => ({
          cart: [...prevState.cart, menuItemDescription]
        }));
        
      } else {
        this.setState({cartPrice: this.state.cartPrice - parseFloat(menuItemPrice)});
        let index = this.state.cart.indexOf(menuItemDescription);
        this.setState({
          cart: this.state.cart.filter((_, i) => i !== index)
        });
        
      }
    }

    render() {
      var settings = {
        dots: true,
        infinite: false,
      };
      var pagesToHtml = {'1': <div style={{marginBottom:'10px'}}><a target="_blank" href="https://cdn.discordapp.com/attachments/485618849501282305/587373485974749248/image0.jpg">Page Image - SleightlyFusical</a>&nbsp;|&nbsp;<a target="_blank" href="https://cdn.discordapp.com/attachments/485618849501282305/587373513908813824/image0.jpg">Page Image - BoxyRae</a></div>,
      '2': <div style={{marginBottom:'10px'}}><a target="_blank" href="https://cdn.discordapp.com/attachments/485618849501282305/587373533856923651/image0.jpg">Page Image - SleightlyFusical</a>&nbsp;|&nbsp;<a target="_blank" href="https://cdn.discordapp.com/attachments/485618849501282305/587373554002296861/image0.jpg">Page Image - BoxyRae</a></div>,
      '3': <div style={{marginBottom:'10px'}}><a target="_blank" href="https://cdn.discordapp.com/attachments/485618849501282305/587373564877996066/image0.jpg">Page Image - SleightlyFusical</a>&nbsp;|&nbsp;<a target="_blank" href="https://cdn.discordapp.com/attachments/485618849501282305/587373582171242580/image0.jpg">Page Image - BoxyRae</a></div>,
      '4': <div style={{marginBottom:'10px'}}><a target="_blank" href="https://cdn.discordapp.com/attachments/485618849501282305/587373606527434768/image0.jpg">Page Image - SleightlyFusical</a>&nbsp;|&nbsp;<a target="_blank" href="https://cdn.discordapp.com/attachments/485618849501282305/587373631697453156/image0.jpg">Page Image - BoxyRae</a></div>,
    };
      var menuItemTeamToItemsMap = {};
      var menuItemsLength = this.state.menuItems.length;
      for (var i = 0; i < menuItemsLength; i++) {
          menuItemTeamToItemsMap[this.state.menuItems[i].MenuItemTeam] = menuItemTeamToItemsMap[this.state.menuItems[i].MenuItemTeam] || [];
          menuItemTeamToItemsMap[this.state.menuItems[i].MenuItemTeam].push(this.state.menuItems[i]);
      }

      //Sort by price
      for (var team in menuItemTeamToItemsMap){
        menuItemTeamToItemsMap[team].sort((a, b) => 
          (a.MenuItemPrice !== b.MenuItemPrice) ? parseFloat(a.MenuItemPrice) - parseFloat(b.MenuItemPrice) : a.MenuItemDescription.localeCompare(b.MenuItemDescription)
        );
      }

      var menuItems = [];
      
      menuItems =
      this.state.pages.map((pagesKey, pagesIndex) => 
        <div>
        {Object.keys(menuItemTeamToItemsMap).map((key, index) =>
          {
          return (
          <div key={index}>
            <h1>Team {key}</h1>
                {menuItemTeamToItemsMap[key].map(menuItem => (
                  menuItem.MenuItemPage === pagesKey && 
                  <div className="menu-item-container-wrapper">
                    <div className="menu-item-container">
                    <span key={index} className="menu-item">
                      <input type="checkbox" className="menu-item-checkbox" id={menuItem.MenuItemDescription} onChange={ (e) => this.handleChecked(menuItem.MenuItemDescription, menuItem.MenuItemPrice, e) }/>
                      <label className="menu-item-text" htmlFor={menuItem.MenuItemDescription}>{menuItem.MenuItemDescription}<span className="menu-price">${menuItem.MenuItemPrice}</span></label>
                    </span>
                    </div>
                  </div>
                ))}
          </div>
        )})}
        <hr/>
        <div style={{textAlign: 'center'}}>
          {pagesToHtml[pagesKey]}
          - {pagesKey} -
        </div>
        <hr/>
        </div>
      );
      

      return (
        <div id="content" className="content">
        <div className="menu-container blue-frame">
            <div>
              Instructions:
              <ol>
                <li>
                  Take a look at the menu's page image at the bottom of the menu
                </li>
                <li>
                  Select the items you would like to buy
                </li>
                <li>
                  Scroll down to the cart and select 'Copy'
                </li>
                <li>
                  Click 'Check Out' to go to Streamlabs and paste the copied message into the 'Donation Message' section
                </li>
                <li>
                  Modify the donation message with any details on who you want the item delivered to
                </li>
                <li>
                  Come back to this page and click 'Copy Total' to copy the total cart amount
                </li>
                <li>
                  Go back to the Streamlabs page and paste the total under 'Donation Amount'
                </li>
                <li>
                  Fill in your username and click 'Donate'
                </li>
              </ol>
              Note: You can also use bits!
            </div>
          </div>
        <div className="menu-container blue-frame">
            <h1 className="menu-container-title">Menu</h1>
            <Slider {...settings}>
              { menuItems.length > 0 ? menuItems : <span>There are no items available</span>}
            </Slider>
          </div>
          
        <div className="menu-container blue-frame" style={{marginBottom: '0px'}}>
            <h1 className="menu-container-title">Cart</h1>
            <div style={{marginTop: '25px'}}>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-8">
                    <div className="input-group">
                      <textarea id="cart" variant="dark" className="form-control" value={this.state.cart.join(", ")}  ref={(textarea) => this.textArea = textarea}/>
                      <span className="input-group-addon btn btn-primary cart-input-group-addon" onClick={this.copyCartClicked}>Copy</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div id="checkout-cart-section">
                      <div>Total Cart Price: $<span ref={(cartPrice) => this.cartPrice = cartPrice}>{this.state.cartPrice}</span>
                        <Button id="copy-total" variant="dark" className="btn btn-primary" onClick={this.copyTotalClicked}>Copy Total</Button>
                      </div>
                      <div><Button id="checkout-cart" variant="dark" className="btn btn-primary" onClick={this.checkOutClicked}>Check Out</Button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

export default Menu;



            