import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Modal, Button } from 'react-bootstrap'
import { Formik } from 'formik';
import * as Yup from 'yup';
import './helper.css';

class MenuItemModal extends Component {
    constructor() {
      super();

      this.state = {
        modalIsOpen: false
      };

      this.openModal = this.openModal.bind(this);
      this.afterOpenModal = this.afterOpenModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
    }

    post = async (values) => {
      await API.post('streamercampapi', '/menuitems', {
        headers: {
          'Authorization': 'Bearer ' + this.props.idtoken,
        },
        body: {
          MenuItemKeyWord: values.menuItemKeyWord,
          MenuItemTeam: values.menuItemTeam,
          MenuItemPrice: values.menuItemPrice,
          MenuItemPage: values.menuItemPage,
          MenuItemDescription: values.menuItemDescription
        }
      });
      this.props.onHide();
      this.props.listAll(true);
    }

    componentDidMount(){
    }

    openModal() {
      this.setState({modalIsOpen: true});
    }
  
    afterOpenModal() {
      // references are now sync'd and can be accessed.
      this.subtitle.style.color = '#f00';
    }
  
    closeModal() {
      this.setState({modalIsOpen: false});
    } 

    render() {
      return (
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered="true"
          className="regular-font"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Menu Item
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Formik
            initialValues={{ menuItemKeyWord: "", menuItemDescription: "" , menuItemPrice: "" , menuItemPage: "" , menuItemTeam: "" }}
            onSubmit={(values, { setSubmitting }) => {
              this.post(values);
              setTimeout(() => {
                setSubmitting(false);
              }, 500);
            }}
            validationSchema={Yup.object().shape({
              menuItemKeyWord: Yup.string()
                .required('Required'),
              menuItemDescription: Yup.string()
                .required('Required'),
              menuItemPrice: Yup.number()
                .min(2)
                .required('Required'),
              menuItemPage: Yup.number()
                  .moreThan(0)
                  .required('Required'),
              menuItemTeam: Yup.string()
                .required('Required'),
            })}
          >
          
            {props => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;

              return (
                <form onSubmit={handleSubmit} className="form-horizontal">
                  <div className="form-group">
                    <label htmlFor="menuItemKeyWord" style={{ display: 'block' }} className="col-sm-3 control-label"> 
                      Key Word
                    </label>
                    <div className="col-sm-9">
                      <input
                        id="menuItemKeyWord"
                        placeholder="Enter key word to match in messages"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.menuItemKeyWord && touched.menuItemKeyWord ? 'text-input error' : 'text-input'
                        }
                      />
                      {errors.menuItemKeyWord && touched.menuItemKeyWord && (
                        <div className="input-feedback">{errors.menuItemKeyWord}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="menuItemDescription" style={{ display: 'block' }} className="col-sm-3 control-label"> 
                    Description
                    </label>
                    <div className="col-sm-9">
                      <input
                        id="menuItemDescription"
                        placeholder="Description of the item"
                        type="text"
                        value={values.menuItemDescription}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.menuItemDescription && touched.menuItemDescription ? 'text-input error' : 'text-input'
                        }
                      />
                      {errors.menuItemDescription && touched.menuItemDescription && (
                        <div className="input-feedback">{errors.menuItemDescription}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="menuItemPrice" style={{ display: 'block' }} className="col-sm-3 control-label"> 
                      Price
                    </label>
                    <div className="col-sm-9">
                      <input
                        id="menuItemPrice"
                        placeholder="Price of the item"
                        type="number"
                        value={values.menuItemPrice}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.menuItemPrice && touched.menuItemPrice ? 'text-input error' : 'text-input'
                        }
                      />
                      {errors.menuItemPrice && touched.menuItemPrice && (
                        <div className="input-feedback">{errors.menuItemPrice}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="menuItemPage" style={{ display: 'block' }} className="col-sm-3 control-label"> 
                      Page
                    </label>
                    <div className="col-sm-9">
                      <input
                        id="menuItemPage"
                        placeholder="Menu Page #"
                        type="number"
                        value={values.menuItemPage}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.menuItemPage && touched.menuItemPage ? 'text-input error' : 'text-input'
                        }
                      />
                      {errors.menuItemPage && touched.menuItemPage && (
                        <div className="input-feedback">{errors.menuItemPage}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="menuItemTeam" style={{ display: 'block' }} className="col-sm-3 control-label"> 
                    Team
                    </label>
                    <div className="col-sm-9">
                      <select
                        id="menuItemTeam"
                        value={values.menuItemTeam}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ display: 'block' }}
                        className={
                          errors.menuItemTeam && touched.menuItemTeam ? 'text-input error form-control' : 'text-input form-control'
                        }
                      >
                        <option value="" label="Select a team" />
                        <option value="SleightlyFusical" label="SleightlyFusical" />
                        <option value="BoxyRae" label="BoxyRae" />
                      </select>
                      {errors.menuItemTeam && touched.menuItemTeam && (
                        <div className="input-feedback">{errors.menuItemTeam}</div>
                      )}
                    </div>
                  </div>
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary btn btn-default">
                    Add Item!
                  </button>

                </form>
              );
            }}
          </Formik>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      );
    }
}

export default MenuItemModal;



            