// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "streamercamp-hosting",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d1vuv3vepamiwj.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-2:4ff1352d-0d24-4081-b85a-ce7a3fbec882",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_CZUPrqdjI",
    "aws_user_pools_web_client_id": "1aod392qqclti1j5bvmkgohcjk",
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "StreamerCampMenuItems",
            "region": "us-east-2"
        },
        {
            "tableName": "StreamerCampOrders",
            "region": "us-east-2"
        },
        {
            "tableName": "StreamerCampOptions",
            "region": "us-east-2"
        },
        {
            "tableName": "StreamerCampBlogs",
            "region": "us-east-2"
        },
        {
            "tableName": "StreamerCampMedia",
            "region": "us-east-2"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "streamercampapi",
            "endpoint": "https://eu3guh1lzl.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
